import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import ButtonCancel from "../../../UI/ButtonCancel";
import ButtonSave from "../../../UI/ButtonSave";
import CancelIcon from "@mui/icons-material/Cancel";
import { findAllCategorias } from "../../../Categoria/api-categoria";
import { errorMessage } from "../../../util/ConfirmAlertUtil";
import Loading from "../../../UI/Loading";
import { POR_PARTICIPANTE_CATEGORIA } from "../../FormaCobranca";
import Button from "../../../UI/Button";
import Title from "../../../UI/Title";
import IndicacaoFormSearchDialog from "../../../Indicacao/IndicacaoFormSearchDialog";
import { findByEventoDataCategoria, removeEventoDataCategoriaIndicacao, saveEventoDataCategoriaIndicacao } from "./EDCIndicacao/api-evento-data-categoria-indicacao";
import EDCIndicacaoTable from "./EDCIndicacao/EDCIndicacaoTable";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const EventoDataCategoriaForm = ({
  eventoDataCategoria,
  setEventoDataCategoria,
  handleSave,
}) => {
  const [listCategorias, setListCategorias] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [listEDCIndicacoes, setListEDCIndicacoes] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    findAllCategorias().then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListCategorias(data);
        }
      }
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (eventoDataCategoria && !eventoDataCategoria.eNovo) {
      findByEventoDataCategoria(eventoDataCategoria.codigo).then((data) => {
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setListEDCIndicacoes(data);
          }
        }
      })
    }
  }, [eventoDataCategoria])

  const handleDeleteEDCIndicacao = (codigo) => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja deletar esta indicação?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            setIsLoadingRemove(true);
            removeEventoDataCategoriaIndicacao(codigo).then((data) => {
              if (data.error) {
                errorMessage(data.error);
              } else {
                setListEDCIndicacoes((element) => (
                  element.filter(
                    (element) => element.codigo !== codigo
                  )
                ));
                toast.success("Registro excluído com Sucesso.");
              }
              setIsLoadingRemove(false);
            });
          },
        },
        {
          label: "Não",
          onClick: () => { },
        },
      ],
    });
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleAdicionarIndicacao = (indicacao) => {
    const edcIndicacao = {
      indicacao: indicacao,
      eventoDataCategoria: eventoDataCategoria
    }
    saveEventoDataCategoriaIndicacao(edcIndicacao).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListEDCIndicacoes((prevState) => ([
            ...prevState,
            data
          ]))
        }
      }
    })
    setOpenDialog(false);
  }

  const handleOnChange = (event) => {
    setEventoDataCategoria((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCancel = () => {
    setEventoDataCategoria(null);
  };

  const handleSelectCategoriaChange = (event) => {
    setEventoDataCategoria((prevState) => ({
      ...prevState,
      categoria: listCategorias.find(
        (element) => element.codigo === event.target.value
      ),
    }));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <IconButton onClick={handleCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>

      <Grid item xs={12}>
        {isLoading ? (
          <Loading description="Carregando Categorias..." />
        ) : (
          <Box sx={{ minWidth: 120, display: "flex", mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="categoria">Categoria</InputLabel>
              <Select
                labelId="categoria"
                id="categoria"
                value={
                  eventoDataCategoria &&
                    eventoDataCategoria.categoria &&
                    eventoDataCategoria.categoria.codigo
                    ? eventoDataCategoria.categoria.codigo
                    : null
                }
                label="Categoria"
                onChange={handleSelectCategoriaChange}
              >
                {listCategorias.map((c) => (
                  <MenuItem value={c.codigo}>{c.descricao}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      </Grid>

      {eventoDataCategoria.eventoData.evento.formaCobranca &&
        eventoDataCategoria.eventoData.evento.formaCobranca ===
        POR_PARTICIPANTE_CATEGORIA && (
          <>
            <FormGrid item xs={3}>
              <FormLabel htmlFor="valorPorParticipante">
                Valor por Participante
              </FormLabel>
              <OutlinedInput
                id="valorPorParticipante"
                name="valorPorParticipante"
                type="number"
                value={
                  eventoDataCategoria.valorPorParticipante
                    ? eventoDataCategoria.valorPorParticipante
                    : ""
                }
                onChange={handleOnChange}
                placeholder="Valor por Participante"
              />
            </FormGrid>

            <FormGrid item xs={6}>
              <FormLabel htmlFor="taxaExtraAPartirCoreografia">
                Taxa extra a partir de quantas coreografias
              </FormLabel>
              <OutlinedInput
                id="taxaExtraAPartirCoreografia"
                name="taxaExtraAPartirCoreografia"
                type="number"
                value={
                  eventoDataCategoria.taxaExtraAPartirCoreografia
                    ? eventoDataCategoria.taxaExtraAPartirCoreografia
                    : ""
                }
                onChange={handleOnChange}
                placeholder="Taxa extra a partir de quantas coreografias"
              />
            </FormGrid>
            <FormGrid item xs={3}>
              <FormLabel htmlFor="taxaExtra">Taxa extra</FormLabel>
              <OutlinedInput
                id="taxaExtra"
                name="taxaExtra"
                type="number"
                value={
                  eventoDataCategoria.taxaExtra
                    ? eventoDataCategoria.taxaExtra
                    : ""
                }
                onChange={handleOnChange}
                placeholder="Taxa extra"
              />
            </FormGrid>
          </>
        )}

      <FormGrid item xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <ButtonCancel handleCancel={handleCancel} />
          </Grid>
          <Grid item>
            <ButtonSave handleSave={handleSave} />
          </Grid>
        </Grid>
      </FormGrid>
      {!eventoDataCategoria.eNovo && <>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Title>Indicações</Title>
        </Grid>
        <IndicacaoFormSearchDialog
          setIndicacao={handleAdicionarIndicacao}
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
        />
        <Grid item xs={12}>
          <Button onClick={() => setOpenDialog(true)}>Adicionar Indicação</Button>
        </Grid>
        <Grid item xs={12}>
          <EDCIndicacaoTable
            listEDCIndicacoes={listEDCIndicacoes}
            handleDelete={handleDeleteEDCIndicacao}
            isLoadingRemove={isLoadingRemove}
            isLoading={isLoading}

          />
        </Grid>
      </>}

    </Grid>
  );
};

export default EventoDataCategoriaForm;
