import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import Button from "../UI/Button";
import Loading from "../UI/Loading";
import Title from "../UI/Title";

const IndicacaoTableSelectView = ({
  listIndicacoes,
  handleChangeRowsPerPage,
  size,
  handleIndicacaoChangePage,
  setIndicacao,
  isLoading,
}) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Title>Selecione uma Indicação</Title>
      </Grid>
      <TablePagination
        component="div"
        count={listIndicacoes.totalElements}
        page={listIndicacoes.number ? listIndicacoes.number : 0}
        onPageChange={handleIndicacaoChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Descrição</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <Loading description="Carregando Indicações..." />
          ) : (
            <>
              {listIndicacoes.content.length === 0 ? (
                <Typography sx={{ py: 3 }}>Nenhuma indicação cadastrada</Typography>
              ) : (
                <>
                  {listIndicacoes.content.map((indicacao) => (
                    <TableRow>
                      <TableCell>
                        {indicacao.descricao ? indicacao.descricao : ""}
                      </TableCell>

                      <TableCell align="right">
                        <Button
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onClick={() => setIndicacao(indicacao)}
                        >
                          Selecionar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={listIndicacoes.totalElements ? listIndicacoes.totalElements : 0}
        page={listIndicacoes.number ? listIndicacoes.number : 0}
        onPageChange={handleIndicacaoChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
    </React.Fragment>
  );
};

export default IndicacaoTableSelectView;
