import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import Button from "../UI/Button";
import { dateTimeFormat } from "../../util/DateUtil";

export default function CoreografiaProfile({
  coreografia,
  handleTrocaCoreografia,
}) {
  return (
    <React.Fragment>
      <Box sx={{ minWidth: 275 }}>
        <Card variant="outlined">
          <CardContent>
            <Grid container direction="row" spacing={3}>
              <Grid item>
                <Typography variant="h5" component="div">
                  Data Hora Evento
                </Typography>
                <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                  {coreografia.eventoDataCategoria.eventoData
                    ? dateTimeFormat(
                        coreografia.eventoDataCategoria.eventoData
                          .dataHoraEvento
                      )
                    : ""}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" component="div">
                  Categoria
                </Typography>
                <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                  {coreografia.eventoDataCategoria
                    ? coreografia.eventoDataCategoria.categoria.descricao
                    : ""}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" component="div">
                  Coreografia
                </Typography>
                <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                  {coreografia.descricao ? coreografia.descricao : ""}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={handleTrocaCoreografia}
            >
              Trocar Coreografia
            </Button>
          </CardActions>
        </Card>
      </Box>
    </React.Fragment>
  );
}
