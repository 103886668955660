import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from '../../util/FetchUtil';

const controller = 'coreografia-indicacao';

export const findByCoreografiaCodigoAndCurrentUser = (codCoreografia) => {
  const url = SERVER_URL + controller + '/query?cod-coreografia=' + codCoreografia + '&current-user=true';
  return fetchGetAuth(url);
}

export const saveCoreografiaIndicacao = (coreografiaIndicacao) => {
  const url = SERVER_URL + controller + "/save";
  return fetchPostAuth(url, coreografiaIndicacao);
}

export const removeCoreografiaIndicacao = (codigo) => {
  const url = SERVER_URL + controller + "/" + codigo;
  return fetchRemoveAuthReturn(url);
}