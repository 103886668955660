import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import Button from "../../UI/Button";
import { dateTimeFormat } from "../../../util/DateUtil";

export default function EventoDataProfile({
  eventoData,
  handleTrocaEventoData,
}) {
  return (
    <React.Fragment>
      <Box sx={{ minWidth: 275 }}>
        <Card variant="outlined">
          <CardContent>
            <Grid container direction="row" spacing={3}>
              <Grid item>
                <Typography variant="h5" component="div">
                  Evento
                </Typography>
                <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                  {eventoData.evento.descricao}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" component="div">
                  País
                </Typography>
                <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                  {eventoData.evento.pais}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" component="div">
                  Estado
                </Typography>
                <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                  {eventoData.evento.estado}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" component="div">
                  Cidade
                </Typography>
                <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                  {eventoData.evento.cidade}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" component="div">
                  Data Hora
                </Typography>
                <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                  {dateTimeFormat(eventoData.dataHoraEvento)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" component="div">
                  Máximo Coreografias
                </Typography>
                <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                  {eventoData.maximoCoreografias
                    ? eventoData.maximoCoreografias
                    : ""}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" component="div">
                  Máximo Participantes
                </Typography>
                <Typography
                  sx={{
                    color: "text.secondary",
                    mb: 1.5,
                    alignItems: "center",
                  }}
                >
                  {eventoData.maximoParticipantes
                    ? eventoData.maximoParticipantes
                    : ""}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={handleTrocaEventoData}
            >
              Trocar Evento Data
            </Button>
          </CardActions>
        </Card>
      </Box>
    </React.Fragment>
  );
}
