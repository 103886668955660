import { FormLabel, Grid, IconButton, OutlinedInput } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import ButtonCancel from "../../UI/ButtonCancel";
import ButtonSave from "../../UI/ButtonSave";
import CancelIcon from "@mui/icons-material/Cancel";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const UsuarioPerfilForm = ({
  usuarioPerfil,
  setUsuarioPerfil,
  handleSave,
}) => {

  const handleOnChange = (event) => {
    setUsuarioPerfil((prevState) => ({
      ...prevState,
      usuario: {
        ...prevState.usuario,
        email: event.target.value
      }
    }))
  }

  const handleCancel = () => {
    setUsuarioPerfil(null);
  };


  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <IconButton onClick={handleCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <FormGrid item xs={12}>
          <FormLabel htmlFor="email" required>
            Email
          </FormLabel>
          <OutlinedInput
            id="email"
            name="email"
            type="email"
            onChange={handleOnChange}
            value={usuarioPerfil.usuario ? usuarioPerfil.usuario.email : ""}
            placeholder="Email"
            required
          />
        </FormGrid>
      </Grid>


      <FormGrid item xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <ButtonCancel handleCancel={handleCancel} />
          </Grid>
          <Grid item>
            <ButtonSave handleSave={handleSave} />
          </Grid>
        </Grid>
      </FormGrid>
    </Grid>
  );
};

export default UsuarioPerfilForm;
