export const ASSISTENTE = 'ASSISTENTE';
export const BAILARINO = 'BAILARINO';
export const COREOGRAFO = 'COREOGRAFO';
export const DIRETOR = 'DIRETOR';

export const participantePerfilEnumText = (perfil) => {
    if (perfil === ASSISTENTE) {
        return 'Assistente';
    } else if (perfil === BAILARINO) {
        return 'Bailarino';
    } else if (perfil === COREOGRAFO) {
        return 'Coreografo';
    }else if (perfil === DIRETOR) {
        return 'Diretor';
    } else {
        return '';
    }
}