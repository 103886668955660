import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../UI/Button";
import Loading from "../UI/Loading";
import Title from "../UI/Title";
import { confirmAlert } from "react-confirm-alert";
import {
  findByParticipanteCodigo,
  remove,
  saveParticipantePerfil,
} from "./api-participante-perfil";
import { errorMessage } from "../util/ConfirmAlertUtil";
import { toast } from "react-toastify";

const ParticipantePerfilTableInserir = ({ participante }) => {
  const [listParticipantePerfil, setListParticipantePerfil] = useState([]);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [perfilSelected, setPerfilSelected] = useState(null);
  const listPerfis = ["ASSISTENTE", "BAILARINO", "COREOGRAFO", "DIRETOR"];

  useEffect(() => {
    setIsLoading(true);
    findByParticipanteCodigo(participante.codigo).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListParticipantePerfil(data);
        }
      }
      setIsLoading(false);
    });
  }, [participante]);

  const handleAdicionarPerfil = () => {
    if (perfilSelected !== null) {
      const participantePerfil = {
        participante: participante,
        participantePerfilEnum: perfilSelected,
      };
      saveParticipantePerfil(participantePerfil).then((data) => {
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setListParticipantePerfil((prevState) => [data, ...prevState]);
            toast.success("Operação Realizada com sucesso.");
          }
        }
      });
    }
  };

  const handleDelete = (codigo) => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja deletar este perfil?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            setIsLoadingRemove(true);
            remove(codigo).then((data) => {
              if (data.error) {
                errorMessage(data.error);
              } else {
                setListParticipantePerfil((participantePerfilElement) => [
                  participantePerfilElement.filter(
                    (element) => element.codigo !== codigo
                  ),
                ]);
                toast.success("Registro excluído com Sucesso.");
              }
              setIsLoadingRemove(false);
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleChange = (event) => {
    setPerfilSelected(event.target.value);
  };

  return (
    <React.Fragment>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12}>
          <Title>Participante Perfil</Title>
        </Grid>
        <Grid item sx={3}>
          <FormControl fullWidth>
            <InputLabel id="perfil">Perfil</InputLabel>
            <Select
              labelId="perfil"
              id="perfil"
              value={perfilSelected}
              label="Perfil"
              onChange={handleChange}
            >
              {listPerfis.map((element) => (
                <MenuItem value={element}>{element}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sx={3}>
          <Button onClick={handleAdicionarPerfil}>Adicionar Novo Perfil</Button>
        </Grid>
      </Grid>
      <Table size="small" sx={{ mt: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell>Perfil</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <Loading description="Carregando Perfis..." />
          ) : (
            <>
              {listParticipantePerfil.length === 0 ? (
                <Typography sx={{ py: 3 }}>Nenhum perfil cadastrado</Typography>
              ) : (
                <>
                  {listParticipantePerfil.map((participantePerfil) => (
                    <TableRow>
                      <TableCell>
                        {participantePerfil.participantePerfilEnum
                          ? participantePerfil.participantePerfilEnum
                          : ""}
                      </TableCell>

                      <TableCell align="right">
                        {isLoadingRemove ? (
                          <CircularProgress
                            sx={{ mr: 5 }}
                            color="secondary"
                            size={20}
                          />
                        ) : (
                          <DeleteIcon
                            sx={{ mr: 5 }}
                            style={{ cursor: "pointer" }}
                            fontSize="small"
                            onClick={() => {
                              handleDelete(participantePerfil.codigo);
                            }}
                            color="secondary"
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export default ParticipantePerfilTableInserir;
