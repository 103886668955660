import { Grid, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loading from "../UI/Loading";
import Title from "../UI/Title";
import { dateTimeFormat } from "../../util/DateUtil";
import {
  errorBg,
  errorBorder,
  successBg,
  successBorder,
} from "../../util/ColorUtil";
import { Link } from "react-router-dom";
import Button from "../UI/Button";
import DownloadIcon from "@mui/icons-material/Download";
import MenuIcon from '@mui/icons-material/Menu';
import GradeIcon from '@mui/icons-material/Grade';
import CoreografiaNotaFormDialog from "../CoreografiaNota/CoreografiaNotaFormDialog";

const ApresentacaoCockpit = ({ listApresentacoes, isLoading }) => {
  const [dateNow, setDateNow] = useState(new Date());
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [coreografia, setCoreografia] = useState(null);

  useEffect(() => {
    const setActualDate = setInterval(() => {
      setDateNow(new Date());
    }, 60000);
    return () => clearInterval(setActualDate);
  }, []);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenCoreografiaNota = (apresentacao) => {
    setCoreografia(apresentacao.coreografia);
    setOpenDialog(true);
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container direction='row' justifyContent='space-between'>
            <Grid item>
              <Title>Apresentação</Title>
            </Grid>
            <Grid item style={{ cursor: 'pointer' }}>
              <MenuIcon
                onClick={(event) => handleMenuClick(event)}
              />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  component={Link}
                  to='/apresentacao-simplificado'
                  state={{ listApresentacoes: listApresentacoes }}
                >
                  Relatório Simplificado
                </MenuItem>


              </Menu>
            </Grid>
          </Grid>
        </Grid>

        {isLoading ? (
          <Grid item xs={12}>
            <Loading description="Carregando apresentações..." />
          </Grid>
        ) : (
          <>
            {listApresentacoes.length === 0 ? (
              <Grid item xs={12}>
                <Typography sx={{ py: 3 }}>
                  Nenhuma apresentação cadastrada
                </Typography>
              </Grid>
            ) : (
              <>
                {listApresentacoes.map((apresentacao) => (
                  <>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginLeft: "20px",
                        marginBottom: "20px",
                        paddingBottom: "20px",
                        backgroundColor:
                          dateNow.getMilliseconds() >=
                            new Date(
                              apresentacao.dataHoraInicio
                            ).getMilliseconds() &&
                            dateNow.getMilliseconds() <=
                            new Date(apresentacao.dataHoraFim).getMilliseconds()
                            ? successBg
                            : errorBg,
                        borderColor:
                          dateNow.getMilliseconds() >=
                            new Date(
                              apresentacao.dataHoraInicio
                            ).getMilliseconds() &&
                            dateNow.getMilliseconds() <=
                            new Date(apresentacao.dataHoraFim).getMilliseconds()
                            ? successBorder
                            : errorBorder,
                        borderRadius: "20px",
                        borderStyle: "solid",
                      }}
                    >
                      <Grid
                        container
                        spacing={3}
                        direction="row"
                        alignItems="center"
                      >
                        <Grid item xs={12}>
                          {apresentacao.apresentacaoIntervalo ? (
                            <Typography variant="h6">
                              <span style={{ fontWeight: "bolder" }}>
                                Intervalo
                              </span>
                            </Typography>
                          ) : (
                            <Typography variant="h6">
                              <span style={{ fontWeight: "bolder" }}>
                                Instituição:
                              </span>{" "}
                              {apresentacao.coreografia &&
                                apresentacao.coreografia.instituicao.descricao
                                ? apresentacao.coreografia.instituicao.descricao
                                : ""}
                            </Typography>
                          )}
                        </Grid>

                        {apresentacao.apresentacaoIntervalo ? (
                          <>
                            <Grid item xs={12}>
                              <Typography variant="body1">
                                <span style={{ fontWeight: "bolder" }}>
                                  Descrição Intervalo:
                                </span>{" "}
                                {apresentacao.descricao
                                  ? apresentacao.descricao
                                  : ""}
                              </Typography>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={4}>
                              <Typography variant="body1">
                                <span style={{ fontWeight: "bolder" }}>
                                  Categoria:
                                </span>{" "}
                                {apresentacao.coreografia &&
                                  apresentacao.coreografia.eventoDataCategoria
                                    .categoria.descricao
                                  ? apresentacao.coreografia.eventoDataCategoria
                                    .categoria.descricao
                                  : ""}
                              </Typography>
                            </Grid>
                          </>
                        )}
                        {apresentacao.apresentacaoIntervalo ? (
                          <>
                            <Grid item xs={12}>
                              <Typography variant="body1">
                                <span style={{ fontWeight: "bolder" }}>
                                  Conteúdo Apresentador:
                                </span>{" "}
                                {apresentacao.conteudoApresentador
                                  ? apresentacao.conteudoApresentador
                                  : ""}
                              </Typography>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={8}>
                              <Typography variant="body1">
                                <span style={{ fontWeight: "bolder" }}>
                                  Coreografia:
                                </span>{" "}
                                {apresentacao.coreografia &&
                                  apresentacao.coreografia.descricao
                                  ? apresentacao.coreografia.descricao
                                  : ""}
                              </Typography>
                            </Grid>
                          </>
                        )}
                        {!apresentacao.apresentacaoIntervalo && (
                          <>
                            <Grid item xs={4}>
                              <Typography variant="body1">
                                <span style={{ fontWeight: "bolder" }}>
                                  Nome Música:
                                </span>{" "}
                                {apresentacao.coreografia &&
                                  apresentacao.coreografia.nomeMusica
                                  ? apresentacao.coreografia.nomeMusica
                                  : ""}
                              </Typography>
                            </Grid>
                          </>
                        )}
                        {!apresentacao.apresentacaoIntervalo && (
                          <>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <span style={{ fontWeight: "bolder" }}>
                                  Nome Compositor:
                                </span>{" "}
                                {apresentacao.coreografia &&
                                  apresentacao.coreografia.nomeCompositor
                                  ? apresentacao.coreografia.nomeCompositor
                                  : ""}
                              </Typography>
                            </Grid>
                          </>
                        )}
                        <CoreografiaNotaFormDialog
                          openDialog={openDialog}
                          handleCloseDialog={handleCloseDialog}
                          coreografia={coreografia}
                        />
                        {!apresentacao.apresentacaoIntervalo &&
                          <Grid item xs={4}>
                            <Button
                              color="secondary"
                              onClick={() => handleOpenCoreografiaNota(apresentacao)}
                            >
                              <GradeIcon sx={{ mr: 1 }} />
                              Nota
                            </Button>
                          </Grid>
                        }
                        {apresentacao.apresentacaoIntervalo ? (
                          <>
                            {apresentacao.midia &&
                              apresentacao.midia.urlMidia && (
                                <Grid item xs={12}>
                                  <Button
                                    component={Link}
                                    to={apresentacao.midia.urlMidia}
                                    target="_blank"
                                    color="secondary"
                                    download
                                  >
                                    <DownloadIcon sx={{ mr: 1 }} />
                                    Download Música
                                  </Button>
                                </Grid>
                              )}
                          </>
                        ) : (
                          <>
                            {apresentacao.coreografia &&
                              apresentacao.coreografia.urlArquivoMusica && (
                                <Grid item xs={4}>
                                  <Button
                                    component={Link}
                                    to={apresentacao.coreografia.urlArquivoMusica}
                                    target="_blank"
                                    color="secondary"
                                    download
                                  >
                                    <DownloadIcon sx={{ mr: 1 }} />
                                    Download Música
                                  </Button>
                                </Grid>
                              )}
                          </>
                        )}

                        {!apresentacao.apresentacaoIntervalo && (
                          <>
                            <Grid item xs={12}>
                              <Typography variant="body1">
                                <span style={{ fontWeight: "bolder" }}>
                                  Tipo Coreografia:
                                </span>{" "}
                                {apresentacao.coreografia &&
                                  apresentacao.coreografia.coreografiaTipo
                                  ? apresentacao.coreografia.coreografiaTipo
                                  : ""}
                              </Typography>
                            </Grid>
                          </>
                        )}

                        <Grid item xs={4}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: "bolder" }}>Início:</span>{" "}
                            {apresentacao.dataHoraInicio
                              ? dateTimeFormat(apresentacao.dataHoraInicio)
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: "bolder" }}>Fim:</span>{" "}
                            {apresentacao.dataHoraFim
                              ? dateTimeFormat(apresentacao.dataHoraFim)
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: "bolder" }}>Duração:</span>{" "}
                            {apresentacao.duracao ? apresentacao.duracao : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>


                  </>
                ))}
              </>
            )}
          </>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default ApresentacaoCockpit;
