import { SET_LOGOUT } from './actionTypes';

export function logout(state = false, action) {
  switch (action.type) {
    case SET_LOGOUT:
      return action.isLogout;
    
    default:
      return state
  }
}