import { Dialog, FormLabel, Grid, OutlinedInput, styled } from "@mui/material";
import React, { useState } from "react";
import { errorMessage } from "../util/ConfirmAlertUtil";
import IndicacaoTableSelectView from "./IndicacaoTableSelectView";
import { findByDescricao } from "./api-indicacao";
import Button from "../UI/Button";


const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const IndicacaoFormSearchDialog = ({ setIndicacao, openDialog, handleCloseDialog }) => {
  const [listIndicacoes, setListIndicacoes] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(10);
  const descricaoKey = "descricao";
  const [procurar, setProcurar] = useState({
    type: descricaoKey,
    value: ''
  });


  const handleIndicacaoChangePage = (newPage) => {
    fetchIndicacao(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };

  const fetchIndicacao = (newPage) => {
    findByDescricao(procurar.value, newPage, size).then((data) => {
      setIsLoading(true);
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListIndicacoes(data);
        }
      }
      setIsLoading(false);
    });
  };

  const handleOnChange = (event) => {
    setProcurar((prevState) => ({
      ...prevState,
      value: event.target.value
    }))
  }

  return (
    <Dialog onClose={handleCloseDialog} open={openDialog}>
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <FormGrid item xs={12}>
            <FormLabel htmlFor="descricao">
              Descrição
            </FormLabel>
            <OutlinedInput
              id="descricao"
              name="descricao"
              type="name"
              value={
                procurar.value ? procurar.value : ""
              }
              onChange={handleOnChange}
              placeholder="Descrição"
            />
          </FormGrid>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={() => fetchIndicacao(0)}>Buscar</Button>
        </Grid>
        <Grid item xs={12}>
          <IndicacaoTableSelectView
            listIndicacoes={listIndicacoes}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            size={size}
            handleIndicacaoChangePage={handleIndicacaoChangePage}
            setIndicacao={setIndicacao}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default IndicacaoFormSearchDialog;
