import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Title from "../UI/Title";
import {
  findByCurrentUser,
  ativarPerfil,
} from "../Usuario/UsuarioPerfil/api-usuario-perfil";
import { errorMessage } from "../util/ConfirmAlertUtil";
import Loading from "../UI/Loading";

import Button from "../UI/Button";
import { setUsuario } from "../Usuario/actions/actions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

const UsuarioPerfilAtivo = () => {
  const [listUsuarioPerfil, setListUsuarioPerfil] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const usuario = useSelector((state) => state.user);

  useEffect(() => {
    setIsLoading(true);
    findByCurrentUser().then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListUsuarioPerfil(data);
        }
      }
      setIsLoading(false);
    });
  }, []);

  const handleAtivarPerfil = (usuarioPerfil) => {
    ativarPerfil(usuarioPerfil).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          dispatch(setUsuario(data));
          toast.success("Perfil alterado com sucesso");
        }
      }
    });
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Title>Usuário Perfil Ativo</Title>
      </Grid>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Perfil</TableCell>
            <TableCell>Instituicao</TableCell>
            <TableCell>Agencia Evento</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <Loading description="Carregando Perfis..." />
          ) : (
            <>
              {listUsuarioPerfil.length === 0 ? (
                <Typography sx={{ py: 3 }}>Nenhum perfil cadastrado</Typography>
              ) : (
                <>
                  {listUsuarioPerfil.map((usuarioPerfil) => (
                    <TableRow>
                      <TableCell>
                        {usuarioPerfil.perfil ? usuarioPerfil.perfil : ""}
                      </TableCell>
                      <TableCell>
                        {usuarioPerfil.instituicao
                          ? usuarioPerfil.instituicao.descricao
                          : ""}
                      </TableCell>
                      <TableCell>
                        {usuarioPerfil.agenciaEvento
                          ? usuarioPerfil.agenciaEvento.descricao
                          : ""}
                      </TableCell>

                      <TableCell align="right">
                        {usuario.perfil === usuarioPerfil.perfil &&
                        (usuario.instituicao !== null
                          ? usuario.instituicao.codigo ===
                            usuarioPerfil.instituicao.codigo
                            ? true
                            : false
                          : true) &&
                        (usuario.agenciaEvento !== null
                          ? usuario.agenciaEvento.codigo ===
                            usuarioPerfil.agenciaEvento.codigo
                            ? true
                            : false
                          : true) ? (
                          "Ativo"
                        ) : (
                          <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                            onClick={() => handleAtivarPerfil(usuarioPerfil)}
                          >
                            Ativar
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export default UsuarioPerfilAtivo;
