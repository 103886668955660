import { CircularProgress, Container, FormLabel, Grid, OutlinedInput, Typography } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/system";
import Button from "../components/UI/Button";
import { requestResetPassword } from "./api-auth";
import Title from "../components/UI/Title";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const RequestResetPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState({
    content: '',
    type: ''
  })
  const [isLoading, setIsLoading] = useState(false);

  const clickSubmit = () => {
    setIsLoading(true);
    requestResetPassword(email).then((data) => {
      if (data) {
        if (!data.ok) {
          setMessage({
            content: 'Recuperação cancelada.',
            type: 'ERROR'
          });
        } else {
          setMessage({
            content: 'E-mail enviado. Acesse sua conta de e-mail e siga os passos para resetar a sua senha. ' +
              'O link para resetar a senha fircará ativo por 1 hora, caso a troca não for realizada nesse período o processo poderá ser repetido no próximo dia.',
            type: 'SUCCESS'
          });
        }
      }


      setIsLoading(false);
    })

  }


  const handleOnChange = (event) => {
    setEmail(event.target.value)
  }


  return (
    <Container maxWidth="sm" >
      <Grid container spacing={3} sx={{ m: 2, p: 2, display: "flex", flexDirection: "column" }}>

        <Grid item xs={12}>
          <Title>Resetar Senha</Title>
        </Grid>
        <Grid item xs={12}>
          <FormGrid item xs={12}>
            <FormLabel htmlFor="email" >
              Email
            </FormLabel>
            <OutlinedInput
              id="email"
              name="email"
              type="name"
              value={email ? email : ""}
              onChange={handleOnChange}
              placeholder="Email"
            />
          </FormGrid>
        </Grid>

        <FormGrid item xs={12}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Button
                disabled={isLoading}
                onClick={clickSubmit}>
                {isLoading && <CircularProgress size={20} />}
                Enviar E-mail
              </Button>
            </Grid>
          </Grid>
        </FormGrid>
      </Grid>

      {message.type === 'SUCCESS' ?
        <Typography style={{
          border: '1px solid darkgreen',
          padding: '30px',
          borderRadius: '30px',
          backgroundColor: 'palegreen',
        }} >
          {message.content}
        </Typography> :
        ''
      }
      {message.type === 'ERROR' ?
        <Typography style={{
          border: '1px solid Red',
          padding: '30px',
          borderRadius: '30px',
          backgroundColor: 'Tomato',
        }} >
          {message.content}
        </Typography> :
        ''
      }
    </Container>
  );
};

export default RequestResetPassword;
