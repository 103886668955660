import { ADMIN, DIRETOR_EVENTO, DIRETOR_INSTITUICAO, JURADO } from '../../../components/Usuario/Perfil';

const LateralMenuList = [
    {
        descricao: 'Apresentação',
        access: [ADMIN, DIRETOR_EVENTO],
        link: '/apresentacao'
    },
    {
        descricao: 'Apresentação',
        access: [ADMIN, JURADO],
        link: '/apresentacao-jurado'
    },
    {
        descricao: 'Categoria',
        access: [ADMIN, DIRETOR_EVENTO],
        link: '/categoria'
    },
    {
        descricao: 'Coregografia',
        access: [ADMIN, DIRETOR_INSTITUICAO],
        link: '/coreografia'
    },
    {
        descricao: 'Coreografia Pagamento',
        access: [ADMIN, DIRETOR_INSTITUICAO],
        link: '/coreografia-pagamento'
    },
    {
        descricao: 'Coreografia Participante',
        access: [ADMIN, DIRETOR_INSTITUICAO],
        link: '/coreografia-participante'
    },
    {
        descricao: 'Evento',
        access: [ADMIN, DIRETOR_EVENTO],
        link: '/evento'
    },

    {
        descricao: 'Evento Data',
        access: [ADMIN, DIRETOR_EVENTO],
        link: '/evento-data'
    },
    {
        descricao: 'Evento Data Categoria',
        access: [ADMIN, DIRETOR_EVENTO],
        link: '/evento-data-categoria'
    },
    {
        descricao: 'Indicação',
        access: [ADMIN, DIRETOR_EVENTO],
        link: '/indicacao'
    },
    {
        descricao: 'Jurado',
        access: [ADMIN, DIRETOR_EVENTO],
        link: '/evento-data-jurado'
    },
    {
        descricao: 'Financeiro',
        access: [ADMIN, DIRETOR_EVENTO],
        link: '/evento-coreografia-pagamento'
    },
    {
        descricao: 'Mídia',
        access: [ADMIN, DIRETOR_EVENTO],
        link: '/midia'
    },
    {
        descricao: 'Participante',
        access: [ADMIN, DIRETOR_INSTITUICAO],
        link: '/participante'
    },
    {
        descricao: 'Usuário Perfil',
        access: [ADMIN, DIRETOR_EVENTO],
        link: '/usuario-perfil'
    },




]

export default LateralMenuList;