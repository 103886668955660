import React from "react";
import { Navigate } from "react-router-dom";
import auth from "./auth-helper";

const PrivateRoute = ({ children }) => {
  if (auth.isAuthenticated()) {
    return children;
  } else {
    return (
      <Navigate
        to={{
          pathname: "/",
        }}
      />
    );
  }
};

export default PrivateRoute;
