import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Loading from "../UI/Loading";
import Title from "../UI/Title";
import { participantePerfilEnumText } from "../ParticipantePerfil/ParticipantePerfilEnum";

const CoreografiaParticipantePerfilTable = ({
  listCoreografiaParticipantePerfis,
  handleDelete,
  isLoadingRemove,
  handleChangePage,
  isLoading,
  size,
  handleChangeRowsPerPage,
  coreografia
}) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Title>Coreografia Participante</Title>
      </Grid>
      <TablePagination
        component="div"
        count={listCoreografiaParticipantePerfis.totalElements}
        page={
          listCoreografiaParticipantePerfis.number
            ? listCoreografiaParticipantePerfis.number
            : 0
        }
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Perfil</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <Loading description="Carregando participantes..." />
          ) : (
            <>
              {listCoreografiaParticipantePerfis.content.length === 0 ? (
                <Typography sx={{ py: 3 }}>
                  Nenhum participante cadastrado
                </Typography>
              ) : (
                <>
                  {listCoreografiaParticipantePerfis.content.map((cpp) => (
                    <TableRow>
                      <TableCell>
                        {cpp.participantePerfil
                          ? cpp.participantePerfil.participante.nome
                          : ""}
                      </TableCell>
                      <TableCell>
                        {cpp.participantePerfil
                          ? participantePerfilEnumText(
                              cpp.participantePerfil.participantePerfilEnum
                            )
                          : ""}
                      </TableCell>
                      <TableCell align="right">
                        {!coreografia.finalizadoAdicionarParticipantes && (
                          <>
                            {isLoadingRemove ? (
                              <CircularProgress
                                sx={{ mr: 5 }}
                                color="secondary"
                                size={20}
                              />
                            ) : (
                              <DeleteIcon
                                sx={{ mr: 5 }}
                                style={{ cursor: "pointer" }}
                                fontSize="small"
                                onClick={() => {
                                  handleDelete(cpp.codigo);
                                }}
                                color="secondary"
                              />
                            )}
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={
          listCoreografiaParticipantePerfis.totalElements
            ? listCoreografiaParticipantePerfis.totalElements
            : 0
        }
        page={
          listCoreografiaParticipantePerfis.number
            ? listCoreografiaParticipantePerfis.number
            : 0
        }
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
    </React.Fragment>
  );
};

export default CoreografiaParticipantePerfilTable;
