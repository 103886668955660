import { Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { perfilText } from "./Usuario/Perfil";
import { css } from "@emotion/react";

const WelcomeUser = () => {
  const usuario = useSelector((state) => state.user);

  return (
    <React.Fragment>
      <Typography
        css={css({
          fontWeight: "1000",
        })}
        variant="body1"
      >
        Seja Bem Vindo
      </Typography>
      <Typography variant="body1">
        <span
          css={css({
            fontWeight: "bold",
          })}
        >
          Usuário:
        </span>{" "}
        {usuario ? usuario.nome : ""}
      </Typography>
      <Typography variant="body1">
        <span
          css={css({
            fontWeight: "bold",
          })}
        >
          Perfil:
        </span>{" "}
        {usuario ? perfilText(usuario.perfil) : ""}
      </Typography>
    </React.Fragment>
  );
};

export default WelcomeUser;
