import React from 'react';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

const TextFieldDateTime = (props) => {
    const { onChange, value, label, name } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                onChange={onChange}
                value={value}
                label={label}
                format="dd/MM/yyyy HH:mm"
                name={name}
            />
        </LocalizationProvider>
    );
}

export default TextFieldDateTime;