import { createStore, applyMiddleware, compose } from 'redux';
import appReducer from './reducers'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { thunk } from 'redux-thunk';

const persistConfig = {
    key: 'root',
    storage,
}
const pReducer = persistReducer(persistConfig, appReducer);

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) || compose;

const store = createStore(pReducer, /* preloadedState, */ composeEnhancers(
    applyMiddleware(thunk)));
const persistor = persistStore(store);


export { store, persistor };