import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import CoreografiaParticipantePerfilTable from "./CoreografiaParticipantePerfilTable";
import { toast } from "react-toastify";
import { errorMessage } from "../util/ConfirmAlertUtil";
import { confirmAlert } from "react-confirm-alert";
import {
  findByCoreografiaCodigo,
  removeCoreografiaParticipantePerfil,
  saveCoreografiaParticipantePerfil,
} from "./api-coreografia-participante-perfil";
import CoreografiaTableSelectView from "../Coreografia/CoreografiaTableSelectView";
import CoreografiaProfile from "../Coreografia/CoreografiaProfile";
import { findByInstituicaoCodigo } from "../Coreografia/api-coreografia";
import { finalizarAdicionarParticipantes } from "../CoreografiaPagamento/api-coreografia-pagamento";
import ParticipantePerfilFormSearch from "../ParticipantePerfil/ParticipantePerfilFormSearch";
import Button from "../UI/Button";

const CoreografiaParticipantePerfilContainer = () => {
  const [
    listCoreografiaParticipantePerfis,
    setListCoreografiaParticipantePerfis,
  ] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [listCoreografias, setListCoreografias] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [coreografia, setCoreografia] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    fetchCoreografias(0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (coreografia) {
      fetchCoreografiaParticipantePerfil(0);
    }
    // eslint-disable-next-line
  }, [coreografia]);

  const fetchCoreografias = (newPage) => {
    setIsLoading(true);
    findByInstituicaoCodigo(newPage, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListCoreografias(data);
        }
      }
      setIsLoading(false);
    });
  };

  const fetchCoreografiaParticipantePerfil = (pageNumber) => {
    setIsLoading(true);
    findByCoreografiaCodigo(coreografia.codigo, pageNumber, size).then(
      (data) => {
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setListCoreografiaParticipantePerfis(data);
          }
        }
        setIsLoading(false);
      }
    );
  };

  const handleCoreografiaChangePage = (event, newPage) => {
    fetchCoreografias(newPage);
  };

  const handleCoreografiaParticipantePerfilChangePage = (event, newPage) => {
    fetchCoreografiaParticipantePerfil(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };

  const handleDelete = (codigo) => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja remover este participante?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            setIsLoadingRemove(true);
            removeCoreografiaParticipantePerfil(codigo).then((data) => {
              if (data.error) {
                errorMessage(data.error);
              } else {
                setListCoreografiaParticipantePerfis((coreografiaElement) => ({
                  ...coreografiaElement,
                  content: coreografiaElement.content.filter(
                    (element) => element.codigo !== codigo
                  ),
                }));
                toast.success("Registro excluído com Sucesso.");
              }
              setIsLoadingRemove(false);
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleSave = (participantePerfil) => {
    const coreografiaParticipantePerfil = {
      coreografia: coreografia,
      participantePerfil: participantePerfil,
    };
    saveCoreografiaParticipantePerfil(coreografiaParticipantePerfil).then(
      (data) => {
        setOpenDialog(false);
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setListCoreografiaParticipantePerfis((prevState) => ({
              ...prevState,
              content: [data, ...prevState.content],
            }));
            toast.success("Operação Realizada com Sucesso.");
          }
        }
      }
    );
  };

  const handleTrocaCoreografia = () => {
    setCoreografia(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleFinalizarAdicionarParticipantes = () => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja finalizar o processo de adicionar participantes?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            finalizarAdicionarParticipantes(coreografia.codigo).then((data) => {
              if (data) {
                if (data.error) {
                  errorMessage(data.message);
                } else {
                  setCoreografia(data);
                  toast.success("Operação Realizada com Sucesso.");
                }
              }
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          {coreografia ? (
            <>
              <Grid container direction="column" spacing={2}>
                <Grid item sx={{ display: "flex" }}>
                  <CoreografiaProfile
                    coreografia={coreografia}
                    handleTrocaCoreografia={handleTrocaCoreografia}
                  />
                </Grid>
                <Grid item>
                  <Grid container spacing={3} direction="row">
                    {!coreografia.finalizadoAdicionarParticipantes && (
                      <>
                        <Grid item>
                          {!openDialog && (
                            <Button onClick={() => setOpenDialog(true)}>
                              Adicionar Participante
                            </Button>
                          )}
                          <ParticipantePerfilFormSearch
                            setParticipantePerfil={handleSave}
                            openDialog={openDialog}
                            handleCloseDialog={handleCloseDialog}
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            color="secondary"
                            onClick={handleFinalizarAdicionarParticipantes}
                          >
                            Finalizar Adicionar Participantes
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <CoreografiaParticipantePerfilTable
                    listCoreografiaParticipantePerfis={
                      listCoreografiaParticipantePerfis
                    }
                    handleDelete={handleDelete}
                    isLoadingRemove={isLoadingRemove}
                    handleChangePage={
                      handleCoreografiaParticipantePerfilChangePage
                    }
                    isLoading={isLoading}
                    size={size}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    coreografia={coreografia}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <CoreografiaTableSelectView
                listCoreografias={listCoreografias}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                size={size}
                handleCoreografiaChangePage={handleCoreografiaChangePage}
                setCoreografia={setCoreografia}
                isLoading={isLoading}
              />
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CoreografiaParticipantePerfilContainer;
