import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import Button from "../UI/Button";
import { dateFormat } from "../../util/DateUtil";

export default function EventoProfile({ evento, handleTrocaEvento }) {
  return (
    <React.Fragment>
      <Box sx={{ minWidth: 275 }}>
        <Card variant="outlined">
          <CardContent>
            <Grid container direction="row" spacing={3}>
              <Grid item>
                <Typography variant="h5" component="div">
                  Descrição
                </Typography>
                <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                  {evento.descricao}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" component="div">
                  País
                </Typography>
                <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                  {evento.pais ? evento.pais : ""}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" component="div">
                  Local
                </Typography>
                <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                  {evento.local ? evento.local : ""}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" component="div">
                  Data Evento
                </Typography>
                <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                  {evento.dataEvento ? dateFormat(evento.dataEvento) : ""}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={handleTrocaEvento}
            >
              Trocar Evento
            </Button>
          </CardActions>
        </Card>
      </Box>
    </React.Fragment>
  );
}
