import { Box, FormLabel, Grid, IconButton, OutlinedInput } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import ButtonCancel from "../UI/ButtonCancel";
import ButtonSave from "../UI/ButtonSave";
import CancelIcon from "@mui/icons-material/Cancel";
import TextFieldDate from "../UI/TextFieldDate";
import InputImageByte from "../UI/InputImageByte";
import Button from "../UI/Button";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const ParticipanteForm = ({
  participante,
  setParticipante,
  handleSave,
  handleUploadDocument,
  isLoadingAction,
  handleRemoveUploadedDocument,
}) => {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (file) {
      // create the preview
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [file]);

  const handleOnChange = (event) => {
    setParticipante((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleDateOnChange = (dateSelected, name) => {
    setParticipante((prevState) => ({
      ...prevState,
      [name]: dateSelected,
    }));
  };

  const handleCancel = () => {
    setParticipante(null);
  };

  const handleChangeImage = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUploadDocumentInit = () => {
    if (file === null) {
      return;
    }

    const fileFormData = new FormData();
    fileFormData.append("file", file);
    handleUploadDocument(fileFormData);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <IconButton onClick={handleCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <FormGrid item xs={4}>
          <FormLabel htmlFor="nome" required>
            Nome
          </FormLabel>
          <OutlinedInput
            id="nome"
            name="nome"
            type="name"
            value={participante.nome ? participante.nome : ""}
            onChange={handleOnChange}
            placeholder="Nome"
            required
          />
        </FormGrid>
      </Grid>
      {!participante.eNovo && (
        <Grid item xs={12}>
          <Box component="section" sx={{ p: 2, border: "1px dashed grey" }}>
            <FormGrid item xs={4}>
              <FormLabel>Documento</FormLabel>
              {participante.urlDocumento !== null &&
              participante.urlDocumento !== "" ? (
                <>
                  <img
                    style={{
                      width: "200px",
                    }}
                    src={participante.urlDocumento}
                    alt=""
                  />
                  <Button sx={{ mt: 2 }} onClick={handleRemoveUploadedDocument}>
                    Remover Documento
                  </Button>
                </>
              ) : (
                <>
                  <FormLabel htmlFor="documentFile">
                    Selecione a Imagem do documento
                  </FormLabel>

                  <InputImageByte
                    id="documentFile"
                    name="documentFile"
                    image={preview}
                    handleChangeImage={handleChangeImage}
                    multiple={false}
                  />
                  {file && (
                    <Button
                      sx={{ mt: 2 }}
                      disabled={isLoadingAction}
                      onClick={handleUploadDocumentInit}
                    >
                      Upload Documento
                    </Button>
                  )}
                </>
              )}
            </FormGrid>
          </Box>
        </Grid>
      )}

      <FormGrid item xs={4}>
        <FormLabel htmlFor="numeroRg">RG</FormLabel>
        <OutlinedInput
          id="numeroRg"
          name="numeroRg"
          type="name"
          value={participante.numeroRg ? participante.numeroRg : ""}
          onChange={handleOnChange}
          placeholder="RG"
        />
      </FormGrid>
      <FormGrid item xs={4}>
        <FormLabel htmlFor="numeroCpf">CPF</FormLabel>
        <OutlinedInput
          id="numeroCpf"
          name="numeroCpf"
          type="name"
          value={participante.numeroCpf ? participante.numeroCpf : ""}
          onChange={handleOnChange}
          placeholder="CPF"
        />
      </FormGrid>

      <FormGrid item xs={3}>
        <FormLabel htmlFor="dataNascimento">Data Nascimento</FormLabel>
        <TextFieldDate
          onChange={(dateSelected) =>
            handleDateOnChange(dateSelected, "dataNascimento")
          }
          value={
            participante.dataNascimento
              ? new Date(participante.dataNascimento)
              : new Date()
          }
          name="dataNascimento"
        />
      </FormGrid>

      <FormGrid item xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <ButtonCancel handleCancel={handleCancel} />
          </Grid>
          <Grid item>
            <ButtonSave handleSave={handleSave} />
          </Grid>
        </Grid>
      </FormGrid>
    </Grid>
  );
};

export default ParticipanteForm;
