import React, { useEffect, useState } from "react";
import {
  page,
  remove,
  removeUploadedDocument,
  saveParticipante,
  uploadDocument,
} from "./api-participante";
import { errorMessage } from "../util/ConfirmAlertUtil";
import { toast } from "react-toastify";
import { Grid, Paper } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import ParticipanteForm from "./ParticipanteForm";
import ParticipanteTable from "./ParticipanteTable";
import ParticipantePerfilTableInserir from "../ParticipantePerfil/ParticipantePerfilTableInserir";

const ParticipanteContainer = () => {
  const [listParticipantes, setListParticipantes] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [participante, setParticipante] = useState(null);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  useEffect(() => {
    fetchData(0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchData(0);
    // eslint-disable-next-line
  }, [size]);

  const handleChangePage = (event, newPage) => {
    fetchData(newPage);
  };

  const fetchData = (newPage) => {
    setIsLoading(true);
    page(newPage, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListParticipantes(data);
        }
      }
      setIsLoading(false);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    fetchData(0);
  };

  const handleDelete = (codigo) => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja deletar este participante?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            setIsLoadingRemove(true);
            remove(codigo).then((data) => {
              if (data.error) {
                errorMessage(data.error);
              } else {
                setListParticipantes((participanteElement) => ({
                  ...participanteElement,
                  content: participanteElement.content.filter(
                    (element) => element.codigo !== codigo
                  ),
                }));
                setParticipante(null);
                toast.success("Registro excluído com Sucesso.");
              }
              setIsLoadingRemove(false);
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleSave = () => {
    if (participante.nome === null || participante.nome === "") {
      errorMessage("Campo Nome não pode ser vazio.");
    }
    const eNovo = participante.eNovo;
    saveParticipante(participante).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          if (eNovo) {
            setListParticipantes((prevState) => ({
              ...prevState,
              content: [data, ...prevState.content],
            }));
          } else {
            setListParticipantes((prevState) => ({
              ...prevState,
              content: prevState.content.map((content) => {
                if (content.codigo === data.codigo) {
                  return data;
                } else {
                  return content;
                }
              }),
            }));
          }
          setParticipante(data);
          toast.success("Operação Realizada com Sucesso.");
        }
      }
    });
  };

  const handleUploadDocument = (formData) => {
    setIsLoadingAction(true);
    uploadDocument(formData, participante.codigo).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListParticipantes((prevState) => ({
            ...prevState,
            content: prevState.content.map((content) => {
              if (content.codigo === data.codigo) {
                return data;
              } else {
                return content;
              }
            }),
          }));
          setParticipante(data);
          toast.success("Operação Realizada com Sucesso.");
        }
      }

      setIsLoadingAction(false);
    });
  };

  const handleRemoveUploadedDocument = () => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja deletar este documento?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            removeUploadedDocument(participante.codigo).then((data) => {
              if (data) {
                if (data.error) {
                  errorMessage(data.message);
                } else {
                  setListParticipantes((prevState) => ({
                    ...prevState,
                    content: prevState.content.map((content) => {
                      if (content.codigo === data.codigo) {
                        return data;
                      } else {
                        return content;
                      }
                    }),
                  }));
                  setParticipante(data);
                  toast.success("Operação Realizada com Sucesso.");
                }
              }
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          {participante ? (
            <ParticipanteForm
              participante={participante}
              setParticipante={setParticipante}
              handleSave={handleSave}
              handleUploadDocument={handleUploadDocument}
              isLoadingAction={isLoadingAction}
              handleRemoveUploadedDocument={handleRemoveUploadedDocument}
            />
          ) : (
            <ParticipanteTable
              listParticipantes={listParticipantes}
              handleDelete={handleDelete}
              isLoadingRemove={isLoadingRemove}
              setParticipante={setParticipante}
              handleChangePage={handleChangePage}
              isLoading={isLoading}
              size={size}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </Grid>
      {participante && participante.codigo && (
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <ParticipantePerfilTableInserir participante={participante} />
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default ParticipanteContainer;
