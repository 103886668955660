import { SERVER_URL } from '../../../constants';
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from '../../../util/FetchUtil';

const controller = 'usuario-perfil';

export const findByCurrentUser = () => {
  const url = SERVER_URL + controller + '/query?current-user=true';
  return fetchGetAuth(url);
}
export const findByCurrentAgency = (page, size) => {
  const url = SERVER_URL + controller + '/query-page?current-agency=true&page=' + page + '&size=' + size;
  return fetchGetAuth(url);
}

export const findByPerfil = (perfil, page, size) => {
  const url = SERVER_URL + controller + '/query-page?current-agency=true&perfil=' + perfil + '&page=' + page + '&size=' + size;
  return fetchGetAuth(url);
}

export const removeUsuarioPerfilById = (codUsuarioPerfil) => {
  const url = SERVER_URL + controller + '/' + codUsuarioPerfil;
  return fetchRemoveAuthReturn(url);
}

export const saveUsuarioPerfil = (usuarioPerfil) => {
  const url = SERVER_URL + controller + '/save';
  return fetchPostAuth(url, usuarioPerfil);
}

export const ativarPerfil = (usuarioPerfil) => {
  const url = SERVER_URL + controller + '/ativar-perfil';
  return fetchPostAuth(url, usuarioPerfil);
}