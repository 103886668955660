import { FormLabel, Grid, IconButton, OutlinedInput } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import ButtonCancel from "../UI/ButtonCancel";
import ButtonSave from "../UI/ButtonSave";
import CancelIcon from "@mui/icons-material/Cancel";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const IndicacaoForm = ({
  indicacao,
  setIndicacao,
  handleSave,
}) => {
  const handleOnChange = (event) => {
    setIndicacao((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCancel = () => {
    setIndicacao(null);
  };


  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <IconButton onClick={handleCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>
      <FormGrid item xs={4}>
        <FormLabel htmlFor="descricao">Descrição</FormLabel>
        <OutlinedInput
          id="descricao"
          name="descricao"
          inputProps={{ maxLength: 100 }}
          type="name"
          value={indicacao.descricao ? indicacao.descricao : ""}
          onChange={handleOnChange}
          placeholder="Descrição"
          required
        />
      </FormGrid>

      <FormGrid item xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <ButtonCancel handleCancel={handleCancel} />
          </Grid>
          <Grid item>
            <ButtonSave handleSave={handleSave} />
          </Grid>
        </Grid>
      </FormGrid>
    </Grid>
  );
};

export default IndicacaoForm;
