import { Box, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import Button from "../../../UI/Button";
import { dateTimeFormat } from "../../../../util/DateUtil";

export default function EventoDataCategoriaProfile({
  eventoDataCategoria,
  handleTrocaEventoDataCategoria,
}) {
  return (
    <React.Fragment>
      <Box sx={{ minWidth: 275 }}>
        <Card variant="outlined">
          <CardContent>
            <Grid container direction="row" spacing={3}>
              <Grid item>
                <Typography variant="h5" component="div">
                  Descrição Evento
                </Typography>
                <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                  {eventoDataCategoria.eventoData.evento.descricao}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" component="div">
                  Data Hora
                </Typography>
                <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                  {dateTimeFormat(
                    eventoDataCategoria.eventoData.dataHoraEvento
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" component="div">
                  Categoria
                </Typography>
                <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                  {eventoDataCategoria.categoria.descricao}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={handleTrocaEventoDataCategoria}
            >
              Trocar Evento Data Categoria
            </Button>
          </CardActions>
        </Card>
      </Box>
    </React.Fragment>
  );
}
