import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import EventoTable from "./EventoTable";
import EventoForm from "./EventoForm";
import { toast } from "react-toastify";
import { errorMessage } from "../util/ConfirmAlertUtil";
import { page, remove, saveEvento } from "./api-evento";
import { confirmAlert } from "react-confirm-alert";

const EventoContainer = () => {
  const [listEventos, setListEventos] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [evento, setEvento] = useState(null);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(10);

  useEffect(() => {
    fetchData(0);
    // eslint-disable-next-line
  }, []);

  const handleChangePage = (event, newPage) => {
    fetchData(newPage);
  };

  const fetchData = (newPage) => {
    setIsLoading(true);
    page(newPage, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListEventos(data);
        }
      }
      setIsLoading(false);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };

  const handleDelete = (codigo) => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja deletar este evento?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            setIsLoadingRemove(true);
            remove(codigo).then((data) => {
              if (data.error) {
                errorMessage(data.message);
              } else {
                setListEventos((eventoElement) => ({
                  ...eventoElement,
                  content: eventoElement.content.filter(
                    (element) => element.codigo !== codigo
                  ),
                }));
                setEvento(null);
                toast.success("Registro excluído com Sucesso.");
              }
              setIsLoadingRemove(false);
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleSave = () => {
    if (evento.descricao === null || evento.descricao === "") {
      errorMessage("Campo Descrição não pode ser vazio.");
    }
    const eNovo = evento.eNovo;
    saveEvento(evento).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          if (eNovo) {
            setListEventos((prevState) => ({
              ...prevState,
              content: [data, ...prevState.content],
            }));
          } else {
            setListEventos((prevState) => ({
              ...prevState,
              content: prevState.content.map((content) => {
                if (content.codigo === data.codigo) {
                  return data;
                } else {
                  return content;
                }
              }),
            }));
          }
          setEvento(data);
          toast.success("Operação Realizada com Sucesso.");
        }
      }
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          {evento ? (
            <EventoForm
              evento={evento}
              setEvento={setEvento}
              handleSave={handleSave}
            />
          ) : (
            <EventoTable
              listEventos={listEventos}
              handleDelete={handleDelete}
              isLoadingRemove={isLoadingRemove}
              setEvento={setEvento}
              handleChangePage={handleChangePage}
              isLoading={isLoading}
              size={size}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EventoContainer;
