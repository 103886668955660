import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import ApresentacaoTableSelectView from "./ApresentacaoTableSelectView";
import { findByEventoDataCodigo } from "./api-apresentacao";
import { errorMessage } from "../util/ConfirmAlertUtil";

const ApresentacaoSelectDialog = ({
  handleSelected,
  eventoData,
  openDialog,
  handleCloseDialog,
}) => {
  const [listApresentacoes, setListApresentacoes] = useState([]);

  useEffect(() => {
    if (eventoData && eventoData.codigo) {
      findByEventoDataCodigo(eventoData.codigo).then((data) => {
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setListApresentacoes(data);
          }
        }
      });
    }
  }, [eventoData]);

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        onClose={handleCloseDialog}
        open={openDialog}
      >
        <ApresentacaoTableSelectView
          sx={{ p: 5 }}
          handleSelected={handleSelected}
          listApresentacoes={listApresentacoes}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default ApresentacaoSelectDialog;
