import { FormLabel, Grid, IconButton, OutlinedInput } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import ButtonCancel from "../UI/ButtonCancel";
import ButtonSave from "../UI/ButtonSave";
import CancelIcon from "@mui/icons-material/Cancel";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const CategoriaForm = ({ categoria, setCategoria, handleSave }) => {
  const handleOnChange = (event) => {
    setCategoria((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCancel = () => {
    setCategoria(null);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <IconButton onClick={handleCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <FormGrid item xs={4}>
          <FormLabel htmlFor="descricao" required>
            Descricao
          </FormLabel>
          <OutlinedInput
            id="descricao"
            name="descricao"
            type="name"
            value={categoria.descricao ? categoria.descricao : ""}
            onChange={handleOnChange}
            placeholder="Descrição"
            required
          />
        </FormGrid>
      </Grid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="idadeMinima">Idade Mínima</FormLabel>
        <OutlinedInput
          id="idadeMinima"
          name="idadeMinima"
          type="number"
          value={categoria.idadeMinima ? categoria.idadeMinima : ""}
          onChange={handleOnChange}
          placeholder="Idade Mínima"
          required
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="idadeMaxima">Idade Máxima</FormLabel>
        <OutlinedInput
          id="idadeMaxima"
          name="idadeMaxima"
          type="number"
          value={categoria.idadeMaxima ? categoria.idadeMaxima : ""}
          onChange={handleOnChange}
          placeholder="Idade Máxima"
          required
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="tolerancia">Tolerância</FormLabel>
        <OutlinedInput
          id="tolerancia"
          name="tolerancia"
          type="number"
          value={categoria.tolerancia ? categoria.tolerancia : ""}
          onChange={handleOnChange}
          placeholder="Tolerância"
          required
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="minimoParticipantes">
          Mínimo Participantes
        </FormLabel>
        <OutlinedInput
          id="minimoParticipantes"
          name="minimoParticipantes"
          type="number"
          value={
            categoria.minimoParticipantes ? categoria.minimoParticipantes : ""
          }
          onChange={handleOnChange}
          placeholder="Mínimo Participantes"
          required
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="maximoParticipantes">
          Máximo Participantes
        </FormLabel>
        <OutlinedInput
          id="maximoParticipantes"
          name="maximoParticipantes"
          type="number"
          value={
            categoria.maximoParticipantes ? categoria.maximoParticipantes : ""
          }
          onChange={handleOnChange}
          placeholder="Máximo Participantes"
          required
        />
      </FormGrid>
      <FormGrid item xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <ButtonCancel handleCancel={handleCancel} />
          </Grid>
          <Grid item>
            <ButtonSave handleSave={handleSave} />
          </Grid>
        </Grid>
      </FormGrid>
    </Grid>
  );
};

export default CategoriaForm;
