import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchPostAuth } from '../../util/FetchUtil';

const controller = 'apresentacao';

export const findByEventoDataCodigo = (codEventoData) => {
  const url = SERVER_URL + controller + '/query?cod-evento-data=' + codEventoData;
  return fetchGetAuth(url);
}

export const saveAdicionarIntervalo = (apresentacaIntervalo) => {
  const url = SERVER_URL + controller + '/adicionar-intervalo';
  return fetchPostAuth(url, apresentacaIntervalo);
}
export const saveEditarIntervalo = (apresentacao) => {
  const url = SERVER_URL + controller + '/save';
  return fetchPostAuth(url, apresentacao);
}

export const trocarOrdemApresentacao = (apresentacaoIntervalo) => {
  const url = SERVER_URL + controller + '/trocar-ordem-apresentacao';
  return fetchPostAuth(url, apresentacaoIntervalo);
}