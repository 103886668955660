import { SERVER_URL } from '../../../../../constants';
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from '../../../../../util/FetchUtil';

const controller = 'evento-data-categoria-indicacao';

export const findByEventoDataCategoria = (codEventoDataCategoria) => {
  const url = SERVER_URL + controller +
    '/query?cod-evento-data-categoria=' + codEventoDataCategoria;
  return fetchGetAuth(url);
}

export const removeEventoDataCategoriaIndicacao = (codigo) => {
  const url = SERVER_URL + controller + "/" + codigo;
  return fetchRemoveAuthReturn(url);
}

export const saveEventoDataCategoriaIndicacao = (edcIndicacao) => {
  const url = SERVER_URL + controller + "/save";
  return fetchPostAuth(url, edcIndicacao);
}
