import { FormLabel, Grid, IconButton, OutlinedInput } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import ButtonCancel from "../UI/ButtonCancel";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "../UI/Button";
import { Save } from "@mui/icons-material";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const ApresentacaoFormAdicionar = ({
  apresentacaoIntervalo,
  setApresentacaoIntervalo,
  handleSaveAdicionarIntervalo,
}) => {
  const handleOnChange = (event) => {
    setApresentacaoIntervalo((prevState) => ({
      ...prevState,
      apresentacaoInserir: {
        ...prevState.apresentacaoInserir,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleCancel = () => {
    setApresentacaoIntervalo(null);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <IconButton onClick={handleCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>

      <Grid item xs={4}>
        <FormGrid item xs={12}>
          <FormLabel htmlFor="duracao">Duração (minutos)</FormLabel>
          <OutlinedInput
            id="duracao"
            name="duracao"
            type="number"
            value={
              apresentacaoIntervalo.apresentacaoInserir &&
              apresentacaoIntervalo.apresentacaoInserir.duracao
                ? apresentacaoIntervalo.apresentacaoInserir.duracao
                : ""
            }
            onChange={handleOnChange}
            placeholder="Duração"
          />
        </FormGrid>
      </Grid>

      <Grid item xs={12}>
        <FormGrid item xs={12}>
          <FormLabel htmlFor="descricao">Descricao</FormLabel>
          <OutlinedInput
            id="descricao"
            name="descricao"
            multiline
            rows={4}
            inputProps={{ maxLength: 200 }}
            type="name"
            value={
              apresentacaoIntervalo.apresentacaoInserir &&
              apresentacaoIntervalo.apresentacaoInserir.descricao
                ? apresentacaoIntervalo.apresentacaoInserir.descricao
                : ""
            }
            onChange={handleOnChange}
            placeholder="Descrição"
          />
        </FormGrid>
      </Grid>
      <Grid item xs={12}>
        <FormGrid item xs={12}>
          <FormLabel htmlFor="conteudoApresentador">
            Conteúdo Apresentador
          </FormLabel>
          <OutlinedInput
            id="conteudoApresentador"
            multiline
            rows={4}
            name="conteudoApresentador"
            inputProps={{ maxLength: 300 }}
            type="name"
            value={
              apresentacaoIntervalo.apresentacaoInserir &&
              apresentacaoIntervalo.apresentacaoInserir.conteudoApresentador
                ? apresentacaoIntervalo.apresentacaoInserir.conteudoApresentador
                : ""
            }
            onChange={handleOnChange}
            placeholder="Conteúdo Apresentador"
          />
        </FormGrid>
      </Grid>

      <FormGrid item xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <ButtonCancel handleCancel={handleCancel} />
          </Grid>
          {apresentacaoIntervalo.eNovo && (
            <>
              <Grid item>
                <Button onClick={() => handleSaveAdicionarIntervalo("antes")}>
                  <Save sx={{ mr: 2 }} />
                  Salvar Antes
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={() => handleSaveAdicionarIntervalo("depois")}>
                  <Save sx={{ mr: 2 }} />
                  Salvar Depois
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </FormGrid>
    </Grid>
  );
};

export default ApresentacaoFormAdicionar;
