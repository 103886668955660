import { FormLabel, Grid, IconButton, OutlinedInput } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import ButtonCancel from "../UI/ButtonCancel";
import ButtonSave from "../UI/ButtonSave";
import CancelIcon from "@mui/icons-material/Cancel";
import TextFieldDate from "../UI/TextFieldDate";
import Button from "../UI/Button";
import DeleteIcon from "@mui/icons-material/Delete";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const CoreografiaPagamentoForm = ({
  coreografiaPagamento,
  setCoreografiaPagamento,
  handleSave,
  handleDelete,
  isLoadingRemove,
}) => {
  const handleOnChange = (event) => {
    setCoreografiaPagamento((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCancel = () => {
    setCoreografiaPagamento(null);
  };

  const handleDateOnChange = (dateSelected, name) => {
    setCoreografiaPagamento((prevState) => ({
      ...prevState,
      [name]: dateSelected,
    }));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <IconButton onClick={handleCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <FormGrid item xs={4}>
          <FormLabel htmlFor="coreografia">Coreografia</FormLabel>
          <OutlinedInput
            id="coreografia"
            name="coreografia"
            readOnly={true}
            type="name"
            value={
              coreografiaPagamento.coreografia
                ? coreografiaPagamento.coreografia.descricao
                : ""
            }
            placeholder="Coreografia"
          />
        </FormGrid>
      </Grid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="descricao">Descricao</FormLabel>
        <OutlinedInput
          id="descricao"
          name="descricao"
          type="name"
          inputProps={{ maxLength: 300 }}
          value={
            coreografiaPagamento.descricao ? coreografiaPagamento.descricao : ""
          }
          onChange={handleOnChange}
          placeholder="Descrição"
          required
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="dataEmissao">Data Emissão</FormLabel>
        <TextFieldDate
          onChange={(dateSelected) =>
            handleDateOnChange(dateSelected, "dataEmissao")
          }
          value={
            coreografiaPagamento.dataEmissao
              ? new Date(coreografiaPagamento.dataEmissao)
              : null
          }
          label="Data Emissão"
          name="dataEmissao"
        />
      </FormGrid>

      <FormGrid item xs={3}>
        <FormLabel htmlFor="valor">Valor</FormLabel>
        <OutlinedInput
          id="valor"
          name="valor"
          type="number"
          value={coreografiaPagamento.valor ? coreografiaPagamento.valor : ""}
          onChange={handleOnChange}
          placeholder="Valor"
          required
        />
      </FormGrid>

      <FormGrid item xs={3}>
        <FormLabel htmlFor="dataPagamento">Data Pagamento</FormLabel>
        <TextFieldDate
          onChange={(dateSelected) =>
            handleDateOnChange(dateSelected, "dataPagamento")
          }
          value={
            coreografiaPagamento.dataPagamento
              ? new Date(coreografiaPagamento.dataPagamento)
              : null
          }
          label="Data Pagamento"
          name="dataPagamento"
        />
      </FormGrid>

      <FormGrid item xs={3}>
        <FormLabel htmlFor="valorRecebido">Valor Recebido</FormLabel>
        <OutlinedInput
          id="valorRecebido"
          name="valorRecebido"
          type="number"
          value={
            coreografiaPagamento.valorRecebido
              ? coreografiaPagamento.valorRecebido
              : ""
          }
          onChange={handleOnChange}
          placeholder="Valor Recebido"
          required
        />
      </FormGrid>

      <FormGrid item xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <ButtonCancel handleCancel={handleCancel} />
          </Grid>
          <Grid item>
            <Button
              disabled={isLoadingRemove}
              onClick={() => handleDelete(coreografiaPagamento.codigo)}
            >
              <DeleteIcon sx={{ mr: 2 }} />
              Remover
            </Button>
          </Grid>
          <Grid item>
            <ButtonSave handleSave={handleSave} />
          </Grid>
        </Grid>
      </FormGrid>
    </Grid>
  );
};

export default CoreografiaPagamentoForm;
