import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import Button from "../../../UI/Button";
import Loading from "../../../UI/Loading";
import Title from "../../../UI/Title";
import { dateTimeFormat } from "../../../../util/DateUtil";

const EventoDataJuradoTableSelectView = ({
  listEventoDataJurados,
  handleChangeRowsPerPage,
  size,
  handleEventoDataJuradoChangePage,
  setEventoDataJurado,
  isLoading,
}) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Title>Selecione um Evento Data Jurado</Title>
      </Grid>
      <TablePagination
        component="div"
        count={listEventoDataJurados.totalElements}
        page={listEventoDataJurados.number ? listEventoDataJurados.number : 0}
        onPageChange={handleEventoDataJuradoChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Evento</TableCell>
            <TableCell>Evento Data</TableCell>
            <TableCell>Jurado</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <Loading description="Carregando Evento Data Jurado..." />
          ) : (
            <>
              {listEventoDataJurados.content.length === 0 ? (
                <Typography sx={{ py: 3 }}>Nenhum evento data jurado cadastrado</Typography>
              ) : (
                <>
                  {listEventoDataJurados.content.map((eventoDataJurado) => (
                    <TableRow>
                      <TableCell>
                        {eventoDataJurado.eventoData.evento.descricao
                          ? eventoDataJurado.eventoData.evento.descricao
                          : ""}
                      </TableCell>
                      <TableCell>
                        {eventoDataJurado.eventoData.dataHoraEvento
                          ? dateTimeFormat(eventoDataJurado.eventoData.dataHoraEvento)
                          : ""}
                      </TableCell>
                      <TableCell>
                        {eventoDataJurado.usuarioPerfil.usuario
                          ? eventoDataJurado.usuarioPerfil.usuario.nome
                          : ""}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onClick={() => setEventoDataJurado(eventoDataJurado)}
                        >
                          Selecionar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={
          listEventoDataJurados.totalElements ? listEventoDataJurados.totalElements : 0
        }
        page={listEventoDataJurados.number ? listEventoDataJurados.number : 0}
        onPageChange={handleEventoDataJuradoChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
    </React.Fragment>
  );
};

export default EventoDataJuradoTableSelectView;
