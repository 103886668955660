import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import Button from "../UI/Button";
import Loading from "../UI/Loading";
import Title from "../UI/Title";

const ParticipantePerfilTableSelectView = ({
  listParticipantePerfis,
  handleChangeRowsPerPage,
  size,
  handleParticipantePerfilChangePage,
  setParticipantePerfil,
  isLoading,
}) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Title>Selecione um Participante Perfil</Title>
      </Grid>
      <TablePagination
        component="div"
        count={listParticipantePerfis.totalElements}
        page={listParticipantePerfis.number ? listParticipantePerfis.number : 0}
        onPageChange={handleParticipantePerfilChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Perfil</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <Loading description="Carregando Participante Perfil..." />
          ) : (
            <>
              {listParticipantePerfis.content.length === 0 ? (
                <Typography sx={{ py: 3 }}>
                  Nenhum participante perfil cadastrado
                </Typography>
              ) : (
                <>
                  {listParticipantePerfis.content.map((participantePerfil) => (
                    <TableRow>
                      <TableCell>
                        {participantePerfil.participante
                          ? participantePerfil.participante.nome
                          : ""}
                      </TableCell>
                      <TableCell>
                        {participantePerfil.participantePerfilEnum
                          ? participantePerfil.participantePerfilEnum
                          : ""}
                      </TableCell>

                      <TableCell align="right">
                        <Button
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onClick={() =>
                            setParticipantePerfil(participantePerfil)
                          }
                        >
                          Selecionar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={
          listParticipantePerfis.totalElements
            ? listParticipantePerfis.totalElements
            : 0
        }
        page={listParticipantePerfis.number ? listParticipantePerfis.number : 0}
        onPageChange={handleParticipantePerfilChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
    </React.Fragment>
  );
};

export default ParticipantePerfilTableSelectView;
