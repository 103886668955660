import { Dialog, FormControl, FormLabel, Grid, InputLabel, MenuItem, OutlinedInput, Select, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../UI/Button";
import Title from "../UI/Title";
import { useSelector } from "react-redux";
import { errorMessage } from "../util/ConfirmAlertUtil";
import { findTop1ByCoreografiaCodigoAndCurrentUser, saveCoreografiaNota } from "./api-coreografia-nota";
import { toast } from "react-toastify";
import { findByEventoDataCategoria } from "../Evento/EventoData/EventoDataCategoria/EDCIndicacao/api-evento-data-categoria-indicacao";
import { findByCoreografiaCodigoAndCurrentUser, removeCoreografiaIndicacao, saveCoreografiaIndicacao } from "../CoreografiaIndicacao/api-coreografia-indicacao";
import CoreografiaIndicacaoTable from "../CoreografiaIndicacao/CoreografiaIndicacaoTable";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const CoreografiaNotaFormDialog = ({
  openDialog,
  handleCloseDialog,
  coreografia
}) => {
  const usuario = useSelector(state => state.user);
  const [coreografiaNota, setCoreografiaNota] = useState(null);
  const [listEDCIndicacao, setListEDCIndicacao] = useState([]);
  const [listCoreografiaIndicacao, setListCoreografiaIndicacao] = useState([]);
  const [coreografiaIndicacao, setCoreografiaIndicacao] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (coreografia) {
      setIsLoading(true);
      findTop1ByCoreografiaCodigoAndCurrentUser(coreografia.codigo).then((data) => {
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setCoreografiaNota(data);
          }
        }
        setIsLoading(false);
      })
    }
  }, [coreografia]);

  const handleDeleteIndicacao = (codigo) => {
    removeCoreografiaIndicacao(codigo).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.error);
        } else {
          setListCoreografiaIndicacao((prevState) => (
            prevState.filter(
              (element) => element.codigo !== codigo
            )
          ));
          toast.success("Registro excluído com Sucesso.");
        }
      }

    });
  }

  useEffect(() => {
    if (coreografia && coreografia.eventoDataCategoria) {
      findByEventoDataCategoria(coreografia.eventoDataCategoria.codigo).then((data) => {
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setListEDCIndicacao(data);
          }
        }
      })
    }

  }, [coreografia]);

  useEffect(() => {
    if (coreografia) {
      findByCoreografiaCodigoAndCurrentUser(coreografia.codigo).then((data) => {
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setListCoreografiaIndicacao(data);
          }
        }
      })
    }

  }, [coreografia]);

  const handleSave = () => {
    const persist = {
      ...coreografiaNota,
      coreografia: coreografia,
      usuario: usuario,
    }
    saveCoreografiaNota(persist).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setCoreografiaNota(data);
          toast.success("Operação Realizada com sucesso");
        }
      }
    })
  }

  const handleOnChange = (event) => {
    setCoreografiaNota((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }))
  }

  const handleChangeSelect = (event) => {
    const edcIndicacaoSelect = listEDCIndicacao.find((element) => element.indicacao.codigo === event.target.value);
    setCoreografiaIndicacao((prevState) => ({
      ...prevState,
      indicacao: edcIndicacaoSelect.indicacao
    }))
  }

  const handleSaveIndicacao = () => {
    const persist = {
      ...coreografiaIndicacao,
      coreografia: coreografia
    }
    saveCoreografiaIndicacao(persist).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListCoreografiaIndicacao((prevState) => ([
            ...prevState,
            data
          ]))
        }
      }
    })
  }

  return (
    <Dialog onClose={handleCloseDialog} open={openDialog} maxWidth="xl">
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <Title>Jurado</Title>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1">Coreografia: {coreografia && coreografia.descricao ? coreografia.descricao : ''}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1">Jurado: {usuario.nome}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction='row' spacing={3}>
            <Grid item xs={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormGrid item xs={12}>
                    <FormLabel htmlFor="nota">
                      Nota
                    </FormLabel>
                    <OutlinedInput
                      id="nota"
                      name="nota"
                      type="number"
                      value={
                        coreografiaNota && coreografiaNota.nota ? coreografiaNota.nota : ""
                      }
                      onChange={handleOnChange}
                      placeholder="Nota"
                    />
                  </FormGrid>
                </Grid>
                <Grid item xs={12}>
                  <Button onClick={handleSave}>Salvar</Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormGrid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="indicacao">Indicação</InputLabel>
                      <Select
                        labelId="indicacao"
                        id="indicacao"
                        value={
                          coreografiaIndicacao &&
                            coreografiaIndicacao.indicacao ?
                            coreografiaIndicacao.indicacao.codigo :
                            null
                        }
                        label="Indicação"
                        onChange={handleChangeSelect}
                      >
                        {listEDCIndicacao.map((edcIndicacao) => (
                          <MenuItem value={edcIndicacao.indicacao.codigo}>{edcIndicacao.indicacao.descricao}</MenuItem>
                        ))}

                      </Select>
                    </FormControl>

                  </FormGrid>
                </Grid>
                <Grid item xs={12}>
                  <Button onClick={handleSaveIndicacao}>Adicionar Indicação</Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CoreografiaIndicacaoTable
                  listCoreografiaIndicacao={listCoreografiaIndicacao}
                  isLoading={isLoading}
                  handleDelete={handleDeleteIndicacao}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Dialog>
  );
};

export default CoreografiaNotaFormDialog;
