import React, { useState } from "react";
import { saveCredentialK8Pay } from "./api-credential-k8-pay";
import { errorMessage } from "../util/ConfirmAlertUtil";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import CredentialK8PayForm from "./CredentialK8PayForm";
import Title from "../UI/Title";

const CredentialK8PayContainer = () => {
  const [credentialK8Pay, setCredentialK8Pay] = useState({
    clientId: "********",
    clientSecret: "********",
    chaveAES: "********",
  });

  const handleSave = () => {
    if (credentialK8Pay.clientId === null || credentialK8Pay.clientId === "") {
      errorMessage("Campo ClientId não pode ser vazio.");
    } else if (
      credentialK8Pay.clientSecret === null ||
      credentialK8Pay.clientSecret === ""
    ) {
      errorMessage("Campo ClientSecret não pode ser vazio.");
    } else if (
      credentialK8Pay.chaveAES === null ||
      credentialK8Pay.chaveAES === ""
    ) {
      errorMessage("Campo ChaveAES não pode ser vazio.");
    }

    saveCredentialK8Pay(credentialK8Pay).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setCredentialK8Pay({
            clientId: "********",
            clientSecret: "********",
            chaveAES: "********",
          });
          toast.success("Operação Realizada com Sucesso.");
        }
      }
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Title>Credenciais K8 Pay</Title>
      </Grid>
      <Grid item xs={12}>
        <CredentialK8PayForm
          credentialK8Pay={credentialK8Pay}
          setCredentialK8Pay={setCredentialK8Pay}
          handleSave={handleSave}
        />
      </Grid>
    </Grid>
  );
};

export default CredentialK8PayContainer;
