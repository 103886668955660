import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Title from "../UI/Title";
import Button from "../UI/Button";

const UltimosEventosTable = () => {
  const [listEventos, setListEventos] = useState([]);

  useEffect(() => {
    //TODO: Create Fetch to Last 10 Events...
    setListEventos([]);
  }, []);

  return (
    <React.Fragment>
      <Title>Últimos Eventos</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Data Evento</TableCell>
            <TableCell>País</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell align="right">Cidade</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listEventos.length === 0 && (
            <Typography sx={{ py: 3 }}>Nenhum evento cadastrado</Typography>
          )}
          
        </TableBody>
      </Table>
      <Link color="primary" href="#" sx={{ mt: 10 }}>
        <Button>Mais Eventos</Button>
      </Link>
    </React.Fragment>
  );
};

export default UltimosEventosTable;
