import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from '../../util/FetchUtil';

const controller = 'coreografia-participante-perfil';

export const findByCoreografiaCodigo = (codCoreografia, pageNumber, size) => {
  const url = SERVER_URL + controller + '/query-page?cod-coreografia=' + codCoreografia +
    '&page=' + pageNumber + '&size=' + size;
  return fetchGetAuth(url);
}

export const removeCoreografiaParticipantePerfil = (codigo) => {
  const url = SERVER_URL + controller + "/" + codigo;
  return fetchRemoveAuthReturn(url);
}

export const saveCoreografiaParticipantePerfil = (categoria) => {
  const url = SERVER_URL + controller + "/save";
  return fetchPostAuth(url, categoria);
}

