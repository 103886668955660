import {
  Box,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/system";
import ButtonCancel from "../UI/ButtonCancel";
import ButtonSave from "../UI/ButtonSave";
import CancelIcon from "@mui/icons-material/Cancel";
import { COMPETICAO, coreografiaTipoText, MOSTRA } from "./CoreografiaTipo";
import InputMusicByte from "../UI/InputMusicByte";
import Button from "../UI/Button";
import { Link } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const CoreografiaForm = ({
  coreografia,
  setCoreografia,
  handleSave,
  isLoadingAction,
  handleUploadMusic,
  handleRemoveUploadedMusic,
}) => {
  const [file, setFile] = useState(null);

  const handleOnChange = (event) => {
    setCoreografia((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCancel = () => {
    setCoreografia(null);
  };

  const handleUploadMusicInit = () => {
    if (file === null) {
      return;
    }

    const fileFormData = new FormData();
    fileFormData.append("file", file);
    handleUploadMusic(fileFormData);
  };

  const handleChangeMusic = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <IconButton onClick={handleCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <FormGrid item xs={4}>
          <FormLabel htmlFor="descricao" required>
            Coreografia
          </FormLabel>
          <OutlinedInput
            id="descricao"
            inputProps={{ maxLength: 100 }}
            name="descricao"
            type="name"
            value={coreografia.descricao ? coreografia.descricao : ""}
            onChange={handleOnChange}
            placeholder="Coreografia"
            required
          />
        </FormGrid>
      </Grid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="tempoMaximo">Tempo Máximo (Em minutos)</FormLabel>
        <OutlinedInput
          id="tempoMaximo"
          name="tempoMaximo"
          inputProps={{ maxLength: 4 }}
          type="number"
          value={coreografia.tempoMaximo ? coreografia.tempoMaximo : ""}
          onChange={handleOnChange}
          placeholder="Tempo Máximo"
          required
        />
      </FormGrid>
      {!coreografia.eNovo && (
        <Grid item xs={12}>
          <Box component="section" sx={{ p: 2, border: "1px dashed grey" }}>
            <FormGrid item xs={4}>
              <FormLabel>Música</FormLabel>
              {coreografia.urlArquivoMusica !== null &&
              coreografia.urlArquivoMusica !== "" ? (
                <>
                  <Button
                    component={Link}
                    to={coreografia.urlArquivoMusica}
                    target="_blank"
                    download
                  >
                    <DownloadIcon sx={{ mr: 1 }} />
                    Download Música
                  </Button>
                  <Button sx={{ mt: 2 }} onClick={handleRemoveUploadedMusic}>
                    Remover Música
                  </Button>
                </>
              ) : (
                <>
                  <FormLabel htmlFor="documentFile">
                    Selecione a Música
                  </FormLabel>

                  <InputMusicByte
                    id="documentFile"
                    name="documentFile"
                    musicFile={file}
                    handleChangeMusic={handleChangeMusic}
                    multiple={false}
                  />
                  {file && (
                    <Button
                      sx={{ mt: 2 }}
                      disabled={isLoadingAction}
                      onClick={handleUploadMusicInit}
                    >
                      Upload Música
                    </Button>
                  )}
                </>
              )}
            </FormGrid>
          </Box>
        </Grid>
      )}
      <FormGrid item xs={3}>
        <FormLabel htmlFor="nomeMusica">Nome Música</FormLabel>
        <OutlinedInput
          id="nomeMusica"
          name="nomeMusica"
          inputProps={{ maxLength: 150 }}
          type="name"
          value={coreografia.nomeMusica ? coreografia.nomeMusica : ""}
          onChange={handleOnChange}
          placeholder="Nome Música"
          required
        />
      </FormGrid>
      <FormGrid item xs={3}>
        <FormLabel htmlFor="nomeCompositor">Nome Compositor</FormLabel>
        <OutlinedInput
          id="nomeCompositor"
          name="nomeCompositor"
          inputProps={{ maxLength: 150 }}
          type="name"
          value={coreografia.nomeCompositor ? coreografia.nomeCompositor : ""}
          onChange={handleOnChange}
          placeholder="Nome Compositor"
          required
        />
      </FormGrid>
      {/* TODO: Criar enviar arquivo mp3 musica */}
      <FormGrid item xs={3}>
        <InputLabel id="coreografiaTipo">Tipo</InputLabel>
        <Select
          labelId="coreografiaTipo"
          id="coreografiaTipo"
          name="coreografiaTipo"
          value={
            coreografia && coreografia.coreografiaTipo
              ? coreografia.coreografiaTipo
              : null
          }
          label="Tipo"
          onChange={handleOnChange}
        >
          <MenuItem value={MOSTRA}>{coreografiaTipoText(MOSTRA)}</MenuItem>
          <MenuItem value={COMPETICAO}>
            {coreografiaTipoText(COMPETICAO)}
          </MenuItem>
        </Select>
      </FormGrid>
      <FormGrid item xs={6}>
        <FormLabel htmlFor="resumoCoreografia">Resumo Coreografia</FormLabel>
        <OutlinedInput
          id="resumoCoreografia"
          name="resumoCoreografia"
          type="name"
          inputProps={{ maxLength: 600 }}
          multiline
          rows={4}
          value={
            coreografia.resumoCoreografia ? coreografia.resumoCoreografia : ""
          }
          onChange={handleOnChange}
          placeholder="Resumo Coreografia"
          required
        />
      </FormGrid>
      <FormGrid item xs={6}>
        <FormLabel htmlFor="informacoesAdicionais">
          Informações Adicionais
        </FormLabel>
        <OutlinedInput
          id="informacoesAdicionais"
          name="informacoesAdicionais"
          inputProps={{ maxLength: 500 }}
          type="name"
          multiline
          rows={4}
          value={
            coreografia.informacoesAdicionais
              ? coreografia.informacoesAdicionais
              : ""
          }
          onChange={handleOnChange}
          placeholder="Informações Adicionais"
          required
        />
      </FormGrid>
      <FormGrid item xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <ButtonCancel handleCancel={handleCancel} />
          </Grid>
          <Grid item>
            <ButtonSave handleSave={handleSave} />
          </Grid>
        </Grid>
      </FormGrid>
    </Grid>
  );
};

export default CoreografiaForm;
