import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "./Button";

const ButtonCancel = ({ handleCancel }) => {
  return (
    <Button color="secondary" onClick={handleCancel}>
      <CancelIcon sx={{ mr: 2 }} />
      Cancelar
    </Button>
  );
};

export default ButtonCancel;
