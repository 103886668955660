import { SERVER_URL } from '../../../constants';
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from '../../../util/FetchUtil';

const controller = 'evento-data';

export const page = (codEvento, pageNumber, size) => {
  const url = SERVER_URL + controller +
    '/query-page?page=' + pageNumber + '&size=' + size + '&cod-evento=' + codEvento;
  return fetchGetAuth(url);
}

export const remove = (codigo) => {
  const url = SERVER_URL + controller + "/" + codigo;
  return fetchRemoveAuthReturn(url);
}

export const saveEventoData = (categoria) => {
  const url = SERVER_URL + controller + "/save";
  return fetchPostAuth(url, categoria);
}

export const findByAgenciaEventoCodigo = (pageNumber, size) => {
  const url = SERVER_URL + controller +
    '/query-page?page=' + pageNumber + '&size=' + size;
  return fetchGetAuth(url);
}

export const findByEventoCodigo = (codEvento, pageNumber, size) => {
  const url = SERVER_URL + controller +
    '/query-page?page=' + pageNumber + '&size=' + size + '&cod-evento=' + codEvento;
  return fetchGetAuth(url);
}