import { combineReducers } from 'redux'
import { PURGE } from "redux-persist";
import { logout } from './auth/actions/reducers';
import { SET_USUARIO } from './components/Usuario/actions/actionTypes';

function user(state = null, action) {
  switch (action.type) {
    case SET_USUARIO:
      return action.user

    case PURGE:
      return null;

    default:
      return state
  }
}


const appReducer = combineReducers({
  user,
  logout
})
export default appReducer