import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../../../UI/Button";
import Loading from "../../../UI/Loading";
import Title from "../../../UI/Title";
import { dateTimeFormat } from "../../../../util/DateUtil";

const EventoDataCategoriaTable = ({
  listEventoDataCategorias,
  handleDelete,
  isLoadingRemove,
  handleChangePage,
  isLoading,
  size,
  handleChangeRowsPerPage,
  handleNovoRegistro,
  setEventoDataCategoria,
}) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Title>Evento Data Categoria</Title>
      </Grid>

      <Grid item sx={3}>
        <Button onClick={handleNovoRegistro}>Novo Registro</Button>
      </Grid>
      <TablePagination
        component="div"
        count={listEventoDataCategorias.totalElements}
        page={
          listEventoDataCategorias.number ? listEventoDataCategorias.number : 0
        }
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Descrição</TableCell>
            <TableCell>País</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell>Cidade</TableCell>
            <TableCell>Local</TableCell>
            <TableCell>Data Hora</TableCell>
            <TableCell>Categoria</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <Loading description="Carregando Eventos..." />
          ) : (
            <>
              {listEventoDataCategorias.content.length === 0 ? (
                <Typography sx={{ py: 3 }}>
                  Nenhum evento data categoria cadastrado
                </Typography>
              ) : (
                <>
                  {listEventoDataCategorias.content.map(
                    (eventoDataCategoria) => (
                      <TableRow>
                        <TableCell>
                          {eventoDataCategoria.eventoData.evento &&
                          eventoDataCategoria.eventoData.evento.descricao
                            ? eventoDataCategoria.eventoData.evento.descricao
                            : ""}
                        </TableCell>
                        <TableCell>
                          {eventoDataCategoria.eventoData.evento &&
                          eventoDataCategoria.eventoData.evento.pais
                            ? eventoDataCategoria.eventoData.evento.pais
                            : ""}
                        </TableCell>
                        <TableCell>
                          {eventoDataCategoria.eventoData.evento &&
                          eventoDataCategoria.eventoData.evento.estado
                            ? eventoDataCategoria.eventoData.evento.estado
                            : ""}
                        </TableCell>
                        <TableCell>
                          {eventoDataCategoria.eventoData.evento &&
                          eventoDataCategoria.eventoData.evento.cidade
                            ? eventoDataCategoria.eventoData.evento.cidade
                            : ""}
                        </TableCell>
                        <TableCell>
                          {eventoDataCategoria.eventoData.evento &&
                          eventoDataCategoria.eventoData.evento.local
                            ? eventoDataCategoria.eventoData.evento.local
                            : ""}
                        </TableCell>
                        <TableCell>
                          {eventoDataCategoria.eventoData.dataHoraEvento
                            ? dateTimeFormat(
                                eventoDataCategoria.eventoData.dataHoraEvento
                              )
                            : ""}
                        </TableCell>
                        <TableCell>
                          {eventoDataCategoria.categoria
                            ? eventoDataCategoria.categoria.descricao
                            : ""}
                        </TableCell>

                        <TableCell align="right">
                          {isLoadingRemove ? (
                            <CircularProgress
                              sx={{ mr: 5 }}
                              color="secondary"
                              size={20}
                            />
                          ) : (
                            <DeleteIcon
                              sx={{ mr: 5 }}
                              style={{ cursor: "pointer" }}
                              fontSize="small"
                              onClick={() => {
                                handleDelete(eventoDataCategoria.codigo);
                              }}
                              color="secondary"
                            />
                          )}
                          <Button
                            onClick={() =>
                              setEventoDataCategoria(eventoDataCategoria)
                            }
                          >
                            Selecionar
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={
          listEventoDataCategorias.totalElements
            ? listEventoDataCategorias.totalElements
            : 0
        }
        page={
          listEventoDataCategorias.number ? listEventoDataCategorias.number : 0
        }
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
    </React.Fragment>
  );
};

export default EventoDataCategoriaTable;
