import { Dialog, FormLabel, Grid, OutlinedInput } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import Button from "../UI/Button";
import Title from "../UI/Title";
import ButtonCancel from "../UI/ButtonCancel";
import ApresentacaoSelectDialog from "./ApresentacaoSelectDialog";
import { trocarOrdemApresentacao } from "./api-apresentacao";
import { errorMessage } from "../util/ConfirmAlertUtil";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const ApresentacaoTrocarOrdemFormDialog = ({
  openDialog,
  handleCloseDialog,
  eventoData,
  fetchApresentacoes,
}) => {
  const [apresentacao1, setApresentacao1] = useState(null);
  const [apresentacao2, setApresentacao2] = useState(null);
  const [numeroApresentacao, setNumeroApresentacao] = useState(null);
  const [openDialogApresentacaoSelect, setOpenDialogApresentacaoSelect] =
    useState(false);

  const handleSelectApresentacao1 = () => {
    setNumeroApresentacao(1);
    setOpenDialogApresentacaoSelect(true);
  };

  const handleSelectApresentacao2 = () => {
    setNumeroApresentacao(2);
    setOpenDialogApresentacaoSelect(true);
  };

  const handleSelected = (apresentacao) => {
    if (numeroApresentacao === 1) {
      setApresentacao1(apresentacao);
    } else if (numeroApresentacao === 2) {
      setApresentacao2(apresentacao);
    }
    setOpenDialogApresentacaoSelect(false);
  };

  const handleCloseApresentacaoSelectDialog = () => {
    setOpenDialogApresentacaoSelect(false);
  };

  const handleTrocarOrdem = () => {
    const persist = {
      apresentacaoInserir: apresentacao1,
      apresentacaoReferencia: apresentacao2,
    };
    trocarOrdemApresentacao(persist).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          fetchApresentacoes();
          handleCloseDialog();
        }
      }
    });
  };

  return (
    <React.Fragment>
      <ApresentacaoSelectDialog
        handleSelected={handleSelected}
        eventoData={eventoData}
        openDialog={openDialogApresentacaoSelect}
        handleCloseDialog={handleCloseApresentacaoSelectDialog}
      />
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        onClose={handleCloseDialog}
        open={openDialog}
      >
        <Grid container spacing={3} sx={{ p: 5 }}>
          <Grid item xs={12}>
            <Title>Trocar Ordem Apresentação</Title>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={3}>
              <Grid item>
                <FormLabel htmlFor="apresentacao1">Apresentação 1</FormLabel>
              </Grid>
              <FormGrid item xs={3}>
                <OutlinedInput
                  readOnly={true}
                  id="apresentacao1"
                  name="apresentacao1"
                  type="name"
                  value={
                    apresentacao1
                      ? apresentacao1.coreografia !== null
                        ? apresentacao1.coreografia.descricao
                        : "Intervalo"
                      : ""
                  }
                  placeholder="Apresentação 1"
                />
              </FormGrid>
              <FormGrid item xs={3}>
                <Button onClick={handleSelectApresentacao1}>
                  Selecionar Apresentação 1
                </Button>
              </FormGrid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={3}>
              <Grid item>
                <FormLabel htmlFor="apresentacao2">Apresentação 2</FormLabel>
              </Grid>
              <FormGrid item xs={3}>
                <OutlinedInput
                  readOnly={true}
                  id="apresentacao2"
                  name="apresentacao2"
                  type="name"
                  value={
                    apresentacao2
                      ? apresentacao2.coreografia !== null
                        ? apresentacao2.coreografia.descricao
                        : "Intervalo"
                      : ""
                  }
                  placeholder="Apresentação 2"
                />
              </FormGrid>
              <FormGrid item xs={3}>
                <Button onClick={handleSelectApresentacao2}>
                  Selecionar Apresentação 2
                </Button>
              </FormGrid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={3}
              justifyContent="space-between"
              direction="row"
            >
              <Grid item>
                <ButtonCancel handleCancel={handleCloseDialog} />
              </Grid>

              <Grid item>
                <Button onClick={handleTrocarOrdem}>Trocar</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
};

export default ApresentacaoTrocarOrdemFormDialog;
