import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from '../../util/FetchUtil';

const controller = 'genero';

export const page = (pageNumber) => {
  const url = SERVER_URL + controller + '/query-page?page=' + pageNumber;
  return fetchGetAuth(url);
}

export const remove = (codigo) => {
  const url = SERVER_URL + controller + "/" + codigo;
  return fetchRemoveAuthReturn(url);
}

export const saveGenero = (genero) => {
  const url = SERVER_URL + controller + "/save";
  return fetchPostAuth(url, genero);
}