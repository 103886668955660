import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import Title from "../UI/Title";
import { useLocation } from "react-router-dom";

const ApresentacaoTableSimplificada = () => {
  let { state } = useLocation();

  return (
    <React.Fragment>
      <Container maxWidth='xl'>
        <Grid component={Paper} container spacing={3}>
          <Grid item xs={12}>
            <Title>Lista Apresentações</Title>
          </Grid>


          <Grid item>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Instituição</TableCell>
                  <TableCell>Posição</TableCell>
                  <TableCell>Coreografia</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {state &&
                    state.listApresentacoes.length > 0 &&
                    state.listApresentacoes.map((a) => (
                      <TableRow>
                        <TableCell>
                          {a.coreografia &&
                            a.coreografia.instituicao.descricao
                            ? a.coreografia.instituicao.descricao
                            : ""}
                        </TableCell>

                        <TableCell>{a.posicao ? a.posicao : ""}</TableCell>

                        <TableCell>
                          {a.coreografia && a.coreografia.descricao
                            ? a.coreografia.descricao
                            : ""}
                        </TableCell>

                      </TableRow>
                    ))}
                </>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default ApresentacaoTableSimplificada;
