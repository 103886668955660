import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from '../../util/FetchUtil';

const controller = 'coreografia-pagamento';

export const findByCoreografiaCodigo = (codCoreografia, pageNumber, size) => {
  const url = SERVER_URL + controller + '/query-page?cod-coreografia=' + codCoreografia +
    '&page=' + pageNumber + '&size=' + size;
  return fetchGetAuth(url);
}
export const findByEventoCodigo = (codEvento, pageNumber, size) => {
  const url = SERVER_URL + controller + '/query-page?cod-evento=' + codEvento +
    '&page=' + pageNumber + '&size=' + size;
  return fetchGetAuth(url);
}

export const removeCoreografiaPagamento = (codigo) => {
  const url = SERVER_URL + controller + "/" + codigo;
  return fetchRemoveAuthReturn(url);
}

export const saveCoreografiaPagamento = (coreografiaPagamento) => {
  const url = SERVER_URL + controller + "/save";
  return fetchPostAuth(url, coreografiaPagamento);
}

export const criarTransacaoViaPix = (codCoreografia) => {
  const url = SERVER_URL + controller + '/criar-transacao-via-pix/' + codCoreografia;
  return fetchGetAuth(url);
}

export const finalizarAdicionarParticipantes = (codCoreografia) => {
  const url = SERVER_URL + controller + '/finalizar-adicionar-participante/' + codCoreografia;
  return fetchGetAuth(url);
}

export const consultarTransacao = (codCoreografia) => {
  const url = SERVER_URL + controller + '/consulta-transacao/' + codCoreografia;
  return fetchGetAuth(url);
}