import { Box, FormLabel, Grid, IconButton, OutlinedInput } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/system";
import ButtonCancel from "../UI/ButtonCancel";
import CancelIcon from "@mui/icons-material/Cancel";
import TextFieldDateTime from "../UI/TextFieldDateTime";
import ButtonSave from "../UI/ButtonSave";
import MidiaFormSearchDialog from "../Midia/MidiaFormSearchDialog";
import Button from "../UI/Button";
import DownloadIcon from "@mui/icons-material/Download";
import { Link } from "react-router-dom";
import { saveEditarIntervalo } from "./api-apresentacao";
import { errorMessage } from "../util/ConfirmAlertUtil";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const ApresentacaoFormEditar = ({
  apresentacaoIntervalo,
  setApresentacaoIntervalo,
  handleSaveEditarIntervalo,
  fetchApresentacoes,
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOnChange = (event) => {
    setApresentacaoIntervalo((prevState) => ({
      ...prevState,
      apresentacaoReferencia: {
        ...prevState.apresentacaoReferencia,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleCancel = () => {
    setApresentacaoIntervalo(null);
  };

  const handleDateOnChange = (dateSelected, name) => {
    setApresentacaoIntervalo((prevState) => ({
      ...prevState,
      apresentacaoReferencia: {
        ...prevState.apresentacaoReferencia,
        [name]: dateSelected,
      },
    }));
  };

  const handleOpenSearchMidia = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleRemoveMidia = () => {
    const persist = {
      ...apresentacaoIntervalo.apresentacaoReferencia,
      midia: null,
    };
    saveEditarIntervalo(persist).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setApresentacaoIntervalo((prevState) => ({
            ...prevState,
            apresentacaoReferencia: {
              ...data,
            },
          }));
          fetchApresentacoes();
        }
      }
    });
  };

  const handleSelectedMidia = (midia) => {
    const persist = {
      ...apresentacaoIntervalo.apresentacaoReferencia,
      midia: midia,
    };
    saveEditarIntervalo(persist).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setApresentacaoIntervalo((prevState) => ({
            ...prevState,
            apresentacaoReferencia: {
              ...data,
            },
          }));
          setOpenDialog(false);
          fetchApresentacoes(0);
        }
      }
    });
  };

  return (
    <Grid container spacing={3}>
      <MidiaFormSearchDialog
        setMidia={handleSelectedMidia}
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
      />
      <Grid item xs={12}>
        <IconButton onClick={handleCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>

      <>
        <FormGrid item xs={6}>
          <FormLabel htmlFor="dataHoraInicio" required>
            Data Hora Início
          </FormLabel>
          <TextFieldDateTime
            onChange={(dateSelected) =>
              handleDateOnChange(dateSelected, "dataHoraInicio")
            }
            value={
              apresentacaoIntervalo.apresentacaoReferencia &&
              apresentacaoIntervalo.apresentacaoReferencia.dataHoraInicio
                ? new Date(
                    apresentacaoIntervalo.apresentacaoReferencia.dataHoraInicio
                  )
                : null
            }
            name="dataHoraInicio"
          />
        </FormGrid>
        <FormGrid item xs={6}>
          <FormLabel htmlFor="dataHoraFim" required>
            Data Hora Fim
          </FormLabel>
          <TextFieldDateTime
            onChange={(dateSelected) =>
              handleDateOnChange(dateSelected, "dataHoraFim")
            }
            value={
              apresentacaoIntervalo.apresentacaoReferencia &&
              apresentacaoIntervalo.apresentacaoReferencia.dataHoraFim
                ? new Date(
                    apresentacaoIntervalo.apresentacaoReferencia.dataHoraFim
                  )
                : null
            }
            name="dataHoraFim"
          />
        </FormGrid>
      </>

      {apresentacaoIntervalo &&
        apresentacaoIntervalo.apresentacaoReferencia &&
        apresentacaoIntervalo.apresentacaoReferencia.apresentacaoIntervalo && (
          <>
            <Grid item xs={12}>
              <FormGrid item xs={12}>
                <FormLabel htmlFor="descricao">Descricao</FormLabel>
                <OutlinedInput
                  id="descricao"
                  name="descricao"
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 200 }}
                  type="name"
                  value={
                    apresentacaoIntervalo.apresentacaoReferencia &&
                    apresentacaoIntervalo.apresentacaoReferencia.descricao
                      ? apresentacaoIntervalo.apresentacaoReferencia.descricao
                      : ""
                  }
                  onChange={handleOnChange}
                  placeholder="Descrição"
                />
              </FormGrid>
            </Grid>
            <Grid item xs={12}>
              <FormGrid item xs={12}>
                <FormLabel htmlFor="conteudoApresentador">
                  Conteúdo Apresentador
                </FormLabel>
                <OutlinedInput
                  id="conteudoApresentador"
                  multiline
                  rows={4}
                  name="conteudoApresentador"
                  inputProps={{ maxLength: 300 }}
                  type="name"
                  value={
                    apresentacaoIntervalo.apresentacaoReferencia &&
                    apresentacaoIntervalo.apresentacaoReferencia
                      .conteudoApresentador
                      ? apresentacaoIntervalo.apresentacaoReferencia
                          .conteudoApresentador
                      : ""
                  }
                  onChange={handleOnChange}
                  placeholder="Conteúdo Apresentador"
                />
              </FormGrid>
            </Grid>

            <Grid item xs={12}>
              <Box component="section" sx={{ p: 2, border: "1px dashed grey" }}>
                <FormGrid item xs={4}>
                  <FormLabel>Mídia</FormLabel>
                  {apresentacaoIntervalo.apresentacaoReferencia &&
                  apresentacaoIntervalo.apresentacaoReferencia.midia &&
                  apresentacaoIntervalo.apresentacaoReferencia.midia
                    .urlMidia !== null &&
                  apresentacaoIntervalo.apresentacaoReferencia.midia
                    .urlMidia !== "" ? (
                    <>
                      <Button
                        component={Link}
                        to={
                          apresentacaoIntervalo.apresentacaoReferencia.midia
                            .urlMidia
                        }
                        target="_blank"
                        download
                      >
                        <DownloadIcon sx={{ mr: 1 }} />
                        Download Mídia
                      </Button>
                      <Button sx={{ mt: 2 }} onClick={handleRemoveMidia}>
                        Remover Mídia
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button sx={{ mt: 2 }} onClick={handleOpenSearchMidia}>
                        Selecione Mídia
                      </Button>
                    </>
                  )}
                </FormGrid>
              </Box>
            </Grid>
          </>
        )}

      <FormGrid item xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <ButtonCancel handleCancel={handleCancel} />
          </Grid>
          <Grid item>
            <ButtonSave handleSave={handleSaveEditarIntervalo} />
          </Grid>
        </Grid>
      </FormGrid>
    </Grid>
  );
};

export default ApresentacaoFormEditar;
