import { Box, FormLabel, Grid, IconButton, OutlinedInput } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/system";
import ButtonCancel from "../UI/ButtonCancel";
import ButtonSave from "../UI/ButtonSave";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "../UI/Button";
import InputMidiaByte from "../UI/InputMidiaByte";
import DownloadIcon from "@mui/icons-material/Download";
import { Link } from "react-router-dom";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const MidiaForm = ({
  midia,
  setMidia,
  handleSave,
  isLoadingAction,
  handleRemoveUploadedMidia,
  handleUploadMidia,
}) => {
  const handleOnChange = (event) => {
    setMidia((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const [file, setFile] = useState(null);

  const handleCancel = () => {
    setMidia(null);
  };

  const handleChangeMidia = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUploadMidiaInit = () => {
    if (file === null) {
      return;
    }

    const fileFormData = new FormData();
    fileFormData.append("file", file);
    handleUploadMidia(fileFormData);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <IconButton onClick={handleCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>
      <FormGrid item xs={4}>
        <FormLabel htmlFor="nomeMusica">Nome Música</FormLabel>
        <OutlinedInput
          id="nomeMusica"
          name="nomeMusica"
          inputProps={{ maxLength: 150 }}
          type="name"
          value={midia.nomeMusica ? midia.nomeMusica : ""}
          onChange={handleOnChange}
          placeholder="Nome Música"
          required
        />
      </FormGrid>
      <FormGrid item xs={4}>
        <FormLabel htmlFor="nomeCompositor">Nome Compositor</FormLabel>
        <OutlinedInput
          id="nomeCompositor"
          name="nomeCompositor"
          inputProps={{ maxLength: 150 }}
          type="name"
          value={midia.nomeCompositor ? midia.nomeCompositor : ""}
          onChange={handleOnChange}
          placeholder="Nome Compositor"
          required
        />
      </FormGrid>

      {!midia.eNovo && (
        <Grid item xs={12}>
          <Box component="section" sx={{ p: 2, border: "1px dashed grey" }}>
            <FormGrid item xs={4}>
              <FormLabel>Mídia</FormLabel>
              {midia.urlMidia !== null && midia.urlMidia !== "" ? (
                <>
                  <Button
                    component={Link}
                    to={midia.urlMidia}
                    target="_blank"
                    download
                  >
                    <DownloadIcon sx={{ mr: 1 }} />
                    Download Mídia
                  </Button>
                  <Button sx={{ mt: 2 }} onClick={handleRemoveUploadedMidia}>
                    Remover Mídia
                  </Button>
                </>
              ) : (
                <>
                  <FormLabel htmlFor="documentFile">
                    Selecione a Mídia
                  </FormLabel>

                  <InputMidiaByte
                    id="documentFile"
                    name="documentFile"
                    musicFile={file}
                    handleChangeMusic={handleChangeMidia}
                    multiple={false}
                  />
                  {file && (
                    <Button
                      sx={{ mt: 2 }}
                      disabled={isLoadingAction}
                      onClick={handleUploadMidiaInit}
                    >
                      Upload Mídia
                    </Button>
                  )}
                </>
              )}
            </FormGrid>
          </Box>
        </Grid>
      )}

      <FormGrid item xs={6}>
        <FormLabel htmlFor="descricaoMidia">Descrição Mídia</FormLabel>
        <OutlinedInput
          id="descricaoMidia"
          name="descricaoMidia"
          type="name"
          inputProps={{ maxLength: 200 }}
          multiline
          rows={4}
          value={midia.descricaoMidia ? midia.descricaoMidia : ""}
          onChange={handleOnChange}
          placeholder="Descrição Mídia"
          required
        />
      </FormGrid>

      <FormGrid item xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <ButtonCancel handleCancel={handleCancel} />
          </Grid>
          <Grid item>
            <ButtonSave handleSave={handleSave} />
          </Grid>
        </Grid>
      </FormGrid>
    </Grid>
  );
};

export default MidiaForm;
