import { AccountCircle } from "@mui/icons-material";
import { css, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import { persistor } from "../../../configureStore";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link, Navigate } from "react-router-dom";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { setUsuario } from "../../../components/Usuario/actions/actions";

const MenuUser = ({ anchorElUserMenu, setAnchorElUserMenu }) => {
  const usuario = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const [redirect, setRedirect] = useState(false);

  const handleLogout = async () => {
    setAnchorElUserMenu(null);
    localStorage.removeItem("token");
    dispatch(setUsuario(null));
    setRedirect(true);
    await persistor.purge().then((data) => console.log(data));
  };

  const handleClose = () => {
    setAnchorElUserMenu(null);
  };

  if (redirect) {
    return <Navigate to="/" />;
  }
  return (
    <React.Fragment>
      <Menu
        id="simple-menu"
        anchorEl={anchorElUserMenu}
        keepMounted
        open={Boolean(anchorElUserMenu)}
        onClose={handleClose}
      >
        <MenuItem style={{ justifyContent: "center" }}>
          {usuario && usuario.fileName ? (
            <img
              src={usuario.fotoUrl}
              alt=""
              css={css({
                width: "80px",
                borderRadius: "60px",
              })}
            />
          ) : (
            <AccountCircle style={{ fontSize: 50, color: "black" }} />
          )}
        </MenuItem>
        <MenuItem>{usuario && usuario.email ? usuario.email : ""}</MenuItem>
        <MenuItem
          component={Link}
          to={{
            pathname: "/usuario-senha",
            state: {
              usuario: usuario,
            },
          }}
        >
          <VpnKeyIcon
            css={css({
              marginRight: "5px",
            })}
          />
          Trocar Senha
        </MenuItem>
        <MenuItem
          component={Link}
          to={{
            pathname: "/configuracao",
          }}
        >
          <SettingsIcon
            css={css({
              marginRight: "5px",
            })}
          />
          Configuração
        </MenuItem>

        <MenuItem onClick={handleLogout}>
          <MeetingRoomIcon
            css={css({
              marginRight: "5px",
            })}
          />
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default MenuUser;
