import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import CoreografiaTable from "./CoreografiaTable";
import CoreografiaForm from "./CoreografiaForm";
import { toast } from "react-toastify";
import { errorMessage } from "../util/ConfirmAlertUtil";
import {
  findByEventoDataCategoriaCodigo,
  removeCoreografia,
  removeUploadedMusic,
  saveCoreografia,
  uploadMusic,
} from "./api-coreografia";
import { confirmAlert } from "react-confirm-alert";
import { findByDataEventoGreaterThanEqualNow } from "../Evento/api-evento";
import EventoTableSelectView from "../Evento/EventoTableSelectView";
import { findByEventoCodigo } from "../Evento/EventoData/EventoDataCategoria/api-evento-data-categoria";
import EventoDataCategoriaTableSelectView from "../Evento/EventoData/EventoDataCategoria/EventoDataCategoriaTableSelectView";
import EventoProfile from "../Evento/EventoProfile";
import EventoDataCategoriaProfile from "../Evento/EventoData/EventoDataCategoria/EventoDataCategoriaProfile";

const CoreografiaContainer = () => {
  const [listCoreografias, setListCoregrafias] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [coreografia, setCoreografia] = useState(null);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [listEventos, setListEventos] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [evento, setEvento] = useState(null);
  const [listEventoDataCategorias, setListEventoDataCategorias] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [eventoDataCategoria, setEventoDataCategoria] = useState(null);
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  useEffect(() => {
    fetchEventos(0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (evento) {
      fetchEventoDataCategoria(0);
    }
    // eslint-disable-next-line
  }, [evento]);

  useEffect(() => {
    if (eventoDataCategoria) {
      fetchCoreografias(0);
    }
    // eslint-disable-next-line
  }, [eventoDataCategoria]);

  const fetchEventoDataCategoria = (pageNumber) => {
    setIsLoading(true);
    findByEventoCodigo(evento.codigo, pageNumber, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListEventoDataCategorias(data);
        }
      }
      setIsLoading(false);
    });
  };

  const fetchEventos = (pageNumber) => {
    setIsLoading(true);
    findByDataEventoGreaterThanEqualNow(pageNumber, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListEventos(data);
        }
      }
      setIsLoading(false);
    });
  };

  const handleCoreografiaChangePage = (event, newPage) => {
    fetchCoreografias(newPage);
  };

  const handleEventoDataCategoriaChangePage = (event, newPage) => {
    fetchEventoDataCategoria(newPage);
  };

  const fetchCoreografias = (newPage) => {
    setIsLoading(true);
    findByEventoDataCategoriaCodigo(
      eventoDataCategoria.codigo,
      newPage,
      size
    ).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListCoregrafias(data);
        }
      }
      setIsLoading(false);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };

  const handleEventoChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };

  const handleEventoChangePage = (event, newPage) => {
    fetchEventos(newPage);
  };

  const handleDelete = (codigo) => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja deletar esta coreografia?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            setIsLoadingRemove(true);
            removeCoreografia(codigo).then((data) => {
              if (data.error) {
                errorMessage(data.error);
              } else {
                setListCoregrafias((coreografiaElement) => ({
                  ...coreografiaElement,
                  content: coreografiaElement.content.filter(
                    (element) => element.codigo !== codigo
                  ),
                }));
                setCoreografia(null);
                toast.success("Registro excluído com Sucesso.");
              }
              setIsLoadingRemove(false);
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleSave = () => {
    if (coreografia.descricao === null || coreografia.descricao === "") {
      errorMessage("Campo Descrição não pode ser vazio.");
    }
    const eNovo = coreografia.eNovo;
    saveCoreografia(coreografia).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          if (eNovo) {
            setListCoregrafias((prevState) => ({
              ...prevState,
              content: [data, ...prevState.content],
            }));
          } else {
            setListCoregrafias((prevState) => ({
              ...prevState,
              content: prevState.content.map((content) => {
                if (content.codigo === data.codigo) {
                  return data;
                } else {
                  return content;
                }
              }),
            }));
          }
          setCoreografia(data);
          toast.success("Operação Realizada com Sucesso.");
        }
      }
    });
  };

  const handleTrocaEvento = () => {
    setEvento(null);
  };

  const handleTrocaEventoDataCategoria = () => {
    setEventoDataCategoria(null);
  };

  const handleUploadMusic = (formData) => {
    setIsLoadingAction(true);
    uploadMusic(formData, coreografia.codigo).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListCoregrafias((prevState) => ({
            ...prevState,
            content: prevState.content.map((content) => {
              if (content.codigo === data.codigo) {
                return data;
              } else {
                return content;
              }
            }),
          }));
          setCoreografia(data);
          toast.success("Operação Realizada com Sucesso.");
        }
      }

      setIsLoadingAction(false);
    });
  };

  const handleRemoveUploadedMusic = () => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja deletar esta música?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            removeUploadedMusic(coreografia.codigo).then((data) => {
              if (data) {
                if (data.error) {
                  errorMessage(data.message);
                } else {
                  setListCoregrafias((prevState) => ({
                    ...prevState,
                    content: prevState.content.map((content) => {
                      if (content.codigo === data.codigo) {
                        return data;
                      } else {
                        return content;
                      }
                    }),
                  }));
                  setCoreografia(data);
                  toast.success("Operação Realizada com Sucesso.");
                }
              }
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          {evento ? (
            <>
              {eventoDataCategoria ? (
                <>
                  <Grid container direction="column" spacing={2}>
                    <Grid item sx={{ display: "flex" }}>
                      <EventoDataCategoriaProfile
                        eventoDataCategoria={eventoDataCategoria}
                        handleTrocaEventoDataCategoria={
                          handleTrocaEventoDataCategoria
                        }
                      />
                    </Grid>
                    <Grid item>
                      {coreografia ? (
                        <CoreografiaForm
                          coreografia={coreografia}
                          setCoreografia={setCoreografia}
                          handleSave={handleSave}
                          isLoadingAction={isLoadingAction}
                          handleUploadMusic={handleUploadMusic}
                          handleRemoveUploadedMusic={handleRemoveUploadedMusic}
                        />
                      ) : (
                        <CoreografiaTable
                          listCoreografias={listCoreografias}
                          handleDelete={handleDelete}
                          isLoadingRemove={isLoadingRemove}
                          setCoreografia={setCoreografia}
                          handleChangePage={handleCoreografiaChangePage}
                          isLoading={isLoading}
                          size={size}
                          handleChangeRowsPerPage={handleChangeRowsPerPage}
                          eventoDataCategoria={eventoDataCategoria}
                        />
                      )}
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container direction="column" spacing={2}>
                    <Grid item sx={{ display: "flex" }}>
                      <EventoProfile
                        evento={evento}
                        handleTrocaEvento={handleTrocaEvento}
                      />
                    </Grid>
                    <Grid item>
                      <EventoDataCategoriaTableSelectView
                        listEventoDataCategorias={listEventoDataCategorias}
                        handleChangePage={handleEventoDataCategoriaChangePage}
                        isLoading={isLoading}
                        size={size}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        setEventoDataCategoria={setEventoDataCategoria}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          ) : (
            <>
              <EventoTableSelectView
                listEventos={listEventos}
                handleEventoChangeRowsPerPage={handleEventoChangeRowsPerPage}
                sizeEvento={size}
                handleEventoChangePage={handleEventoChangePage}
                setEvento={setEvento}
                isLoadingEvento={isLoading}
              />
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CoreografiaContainer;
