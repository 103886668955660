import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from '../../util/FetchUtil';

const controller = 'participante-perfil';

export const findByParticipanteCodigo = (codParticipante) => {
  const url = SERVER_URL + controller + '/query?codParticipante=' + codParticipante;
  return fetchGetAuth(url);
}

export const findByParticipanteNome = (nome, page, size) => {
  const url = SERVER_URL + controller + '/query-page?nome=' + nome + '&page=' + page + '&size=' + size;
  return fetchGetAuth(url);
}

export const findByParticipantePerfilEnum = (perfil, page, size) => {
  const url = SERVER_URL + controller + '/query-page?perfil=' + perfil + '&page=' + page + '&size=' + size;;
  return fetchGetAuth(url);
}

export const remove = (codigo) => {
  const url = SERVER_URL + controller + "/" + codigo;
  return fetchRemoveAuthReturn(url);
}

export const saveParticipantePerfil = (participantePerfil) => {
  const url = SERVER_URL + controller + "/save";
  return fetchPostAuth(url, participantePerfil);
}