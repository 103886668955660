import {
  FormLabel,
  Grid,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import ButtonCancel from "../UI/ButtonCancel";
import ButtonSave from "../UI/ButtonSave";
import CancelIcon from "@mui/icons-material/Cancel";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const GeneroForm = ({ genero, setGenero, handleSave }) => {
  const handleOnChange = (event) => {
    setGenero((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCancel = () => {
    setGenero(null);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <IconButton onClick={handleCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <FormGrid item xs={4}>
          <FormLabel htmlFor="descricao" required>
            Descricao
          </FormLabel>
          <OutlinedInput
            id="descricao"
            name="descricao"
            type="name"
            value={genero.descricao ? genero.descricao : ""}
            onChange={handleOnChange}
            placeholder="Descrição"
            required
          />
        </FormGrid>
      </Grid>

      <FormGrid item xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <ButtonCancel handleCancel={handleCancel} />
          </Grid>
          <Grid item>
            <ButtonSave handleSave={handleSave} />
          </Grid>
        </Grid>
      </FormGrid>
    </Grid>
  );
};

export default GeneroForm;
