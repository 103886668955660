import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchPostAuth } from '../../util/FetchUtil';

const controller = 'coreografia-nota';

export const findTop1ByCoreografiaCodigoAndCurrentUser = (codCoreografia) => {
  const url = SERVER_URL + controller + '/query?cod-coreografia=' + codCoreografia + '&current-user=true';
  return fetchGetAuth(url);
}

export const saveCoreografiaNota = (coreografiaNota) => {
  const url = SERVER_URL + controller + "/save";
  return fetchPostAuth(url, coreografiaNota);
}
