import React, { useRef } from 'react';
import Button from './Button';

const InputMidiaByte = (props) => {
    const hiddenFileInput = useRef(null);

    const { musicFile, handleChangeMusic, multiple } = props

    const handleAttachFile = () => {
        hiddenFileInput.current.click();
    }

    return (
        <>
            <input
                type="file"
                accept=".mp3, .mp4, .wmv"
                style={{ display: 'none' }}
                ref={hiddenFileInput}
                onChange={handleChangeMusic}
                multiple={multiple ? true : false}
            />
            {musicFile ?
                <>Mídia Selecionada</>
                :
                <p>Nenhuma Mídia Selecionada</p>
            }
            <div>
                <Button
                    variant="contained"
                    color="secondary"
                    component="span"
                    style={{
                        marginTop: '20px',
                        textTransform: 'none',
                    }}
                    onClick={handleAttachFile}
                >
                    Selecionar Mídia
                </Button>
            </div>
        </>
    );
}

export default InputMidiaByte;