import React, { useEffect, useState } from "react";
import { errorMessage } from "../../util/ConfirmAlertUtil";
import { toast } from "react-toastify";
import { Grid, Paper } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import { findByCurrentAgency, removeUsuarioPerfilById, saveUsuarioPerfil } from "./api-usuario-perfil";
import UsuarioPerfilTable from "./UsuarioPerfilTable";
import UsuarioPerfilForm from "./UsuarioPerfilForm";

const UsuarioPerfilContainer = () => {
  const [listUsuarioPerfil, setListUsuarioPerfil] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [usuarioPerfil, setUsuarioPerfil] = useState(null);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(10);

  useEffect(() => {
    fetchData(0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchData(0);
    // eslint-disable-next-line
  }, [size]);

  const handleChangePage = (event, newPage) => {
    fetchData(newPage);
  };

  const fetchData = (newPage) => {
    setIsLoading(true);
    findByCurrentAgency(newPage, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListUsuarioPerfil(data);
        }
      }
      setIsLoading(false);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    fetchData(0);
  };

  const handleDelete = (codigo) => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja remover este usuário perfil?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            setIsLoadingRemove(true);
            removeUsuarioPerfilById(codigo).then((data) => {
              if (data) {
                if (data.error) {
                  errorMessage(data.message);
                } else {
                  setListUsuarioPerfil((participanteElement) => ({
                    ...participanteElement,
                    content: participanteElement.content.filter(
                      (element) => element.codigo !== codigo
                    ),
                  }));
                  setUsuarioPerfil(null);
                  toast.success("Registro excluído com Sucesso.");
                }
                setIsLoadingRemove(false);
              }


            });
          },
        },
        {
          label: "Não",
          onClick: () => { },
        },
      ],
    });
  };

  const handleSave = () => {
    if (usuarioPerfil.usuario === null) {
      errorMessage("Selecione um Usuário.");
    }
    const eNovo = usuarioPerfil.eNovo;
    saveUsuarioPerfil(usuarioPerfil).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          if (eNovo) {
            setListUsuarioPerfil((prevState) => ({
              ...prevState,
              content: [data, ...prevState.content],
            }));
          } else {
            setListUsuarioPerfil((prevState) => ({
              ...prevState,
              content: prevState.content.map((content) => {
                if (content.codigo === data.codigo) {
                  return data;
                } else {
                  return content;
                }
              }),
            }));
          }
          setUsuarioPerfil(data);
          toast.success("Operação Realizada com Sucesso.");
        }
      }
    });
  };


  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          {usuarioPerfil ? (
            <UsuarioPerfilForm
              usuarioPerfil={usuarioPerfil}
              setUsuarioPerfil={setUsuarioPerfil}
              handleSave={handleSave}
            />
          ) : (
            <UsuarioPerfilTable
              listUsuarioPerfil={listUsuarioPerfil}
              handleDelete={handleDelete}
              isLoadingRemove={isLoadingRemove}
              setUsuarioPerfil={setUsuarioPerfil}
              handleChangePage={handleChangePage}
              isLoading={isLoading}
              size={size}
              handleChangeRowsPerPage={handleChangeRowsPerPage}

            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UsuarioPerfilContainer;
