import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import Loading from "../UI/Loading";
import Title from "../UI/Title";
import Button from "../UI/Button";

const CoreografiaIndicacaoTable = ({
  listCoreografiaIndicacao,
  isLoading,
  handleDelete
}) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Title>Coreografia Indicação</Title>
      </Grid>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Indicação</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <Loading description="Carregando indicações..." />
          ) : (
            <>
              {listCoreografiaIndicacao.length === 0 ? (
                <Typography sx={{ py: 3 }}>
                  Nenhuma indicação cadastrada
                </Typography>
              ) : (
                <>
                  {listCoreografiaIndicacao.map(
                    (coreografiaIndicacao) => (
                      <TableRow>
                        <TableCell>
                          {coreografiaIndicacao.indicacao.descricao
                            ? coreografiaIndicacao.indicacao.descricao
                            : ""}
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() =>
                              handleDelete(coreografiaIndicacao.codigo)
                            }
                          >
                            Remover
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>

    </React.Fragment>
  );
};

export default CoreografiaIndicacaoTable;
