export const MOSTRA = 'MOSTRA';
export const COMPETICAO = 'COMPETICAO';

export const coreografiaTipoText = (coreografiaTipo) => {
    if (coreografiaTipo === MOSTRA) {
        return 'Mostra';
    } else if (coreografiaTipo === COMPETICAO) {
        return 'Competição';
    } else {
        return '';
    }
}